import React, { useState } from "react";
import { Pie } from "react-chartjs-2";
import Sidebar from "../dashboard/sidebar";
import Header from "../dashboard/header";

const OverallView = () => {
  const [users, setUsers] = useState([
    { id: "U001", name: "John Doe", quizTitle: "JavaScript Basics", score: 80, maxScore: 100, grade: "A" },
    { id: "U002", name: "Jane Smith", quizTitle: "React Quiz", score: 65, maxScore: 100, grade: "B" },
    { id: "U003", name: "Mike Brown", quizTitle: "Node.js Basics", score: 50, maxScore: 100, grade: "C" },
    { id: "U004", name: "Emily Davis", quizTitle: "JavaScript Basics", score: 90, maxScore: 100, grade: "A" },
  ]);

  const totalUsers = users.length;
  const totalQuizzes = new Set(users.map((user) => user.quizTitle)).size;
  const averageScore = (users.reduce((sum, user) => sum + user.score, 0) / totalUsers).toFixed(2);

  const chartData = {
    labels: ["A Grade", "B Grade", "C Grade", "Others"],
    datasets: [
      {
        data: [
          users.filter((u) => u.grade === "A").length,
          users.filter((u) => u.grade === "B").length,
          users.filter((u) => u.grade === "C").length,
          users.filter((u) => !["A", "B", "C"].includes(u.grade)).length,
        ],
        backgroundColor: ["#4caf50", "#ffa726", "#f44336", "#9e9e9e"],
        borderColor: ["#4caf50", "#ffa726", "#f44336", "#9e9e9e"],
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
  };

  const sortTable = (key) => {
    const sorted = [...users].sort((a, b) => (a[key] > b[key] ? 1 : -1));
    setUsers(sorted);
  };

  return (
    <div className="min-h-screen flex flex-col bg-gray-100">
      {/* Header */}
      <div className="fixed top-0 left-0 right-0 z-20">
        <Header />
      </div>

      {/* Layout */}
      <div className="flex flex-1 pt-16 md:pt-20 overflow-hidden">
        {/* Sidebar */}
        <div className="hidden md:block w-60 bg-white shadow-lg z-10 flex-shrink-0">
          <Sidebar />
        </div>

        {/* Main Content */}
        <div className="flex-1 flex flex-col p-4 sm:p-6 space-y-6 overflow-auto">
          {/* Admin Overview */}
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
            <div className="bg-blue-100 text-blue-800 p-4 rounded-lg shadow text-center">
              <p className="text-lg font-bold">Total Users</p>
              <p className="text-2xl">{totalUsers}</p>
            </div>
            <div className="bg-green-100 text-green-800 p-4 rounded-lg shadow text-center">
              <p className="text-lg font-bold">Total Quizzes</p>
              <p className="text-2xl">{totalQuizzes}</p>
            </div>
            <div className="bg-yellow-100 text-yellow-800 p-4 rounded-lg shadow text-center">
              <p className="text-lg font-bold">Average Score</p>
              <p className="text-2xl">{averageScore}%</p>
            </div>
          </div>

          {/* Chart Section */}
          <div className="bg-white rounded-lg shadow-lg p-6">
            <h3 className="text-lg font-bold text-gray-800 mb-4">Grade Distribution</h3>
            <div className="h-64">
              <Pie data={chartData} options={chartOptions} />
            </div>
          </div>

          {/* User Performance Table */}
          <div className="bg-white rounded-lg shadow-lg p-6">
            <h3 className="text-lg font-bold text-gray-800 mb-4">User Performance</h3>
            <div className="overflow-x-auto">
              <table className="w-full bg-white border rounded-lg">
                <thead>
                  <tr className="bg-[#EFD316] text-gray-800 uppercase text-sm leading-normal">
                    <th
                      className="py-3 px-6 text-left cursor-pointer hover:bg-[#e5c30f]"
                      onClick={() => sortTable("name")}
                    >
                      Name
                    </th>
                    <th
                      className="py-3 px-6 text-left cursor-pointer hover:bg-[#e5c30f]"
                      onClick={() => sortTable("quizTitle")}
                    >
                      Quiz Title
                    </th>
                    <th
                      className="py-3 px-6 text-center cursor-pointer hover:bg-[#e5c30f]"
                      onClick={() => sortTable("score")}
                    >
                      Score
                    </th>
                    <th
                      className="py-3 px-6 text-center cursor-pointer hover:bg-[#e5c30f]"
                      onClick={() => sortTable("grade")}
                    >
                      Grade
                    </th>
                  </tr>
                </thead>
                <tbody className="text-gray-600 text-sm font-light">
                  {users.map((user, index) => (
                    <tr key={index} className="border-b bg-white hover:bg-gray-50">
                      <td className="py-3 px-6 text-left">{user.name}</td>
                      <td className="py-3 px-6 text-left">{user.quizTitle}</td>
                      <td className="py-3 px-6 text-center">{user.score}/{user.maxScore}</td>
                      <td className="py-3 px-6 text-center">{user.grade}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverallView;

import React, { useState, useEffect } from "react";
import Sidebar from "../dashboard/sidebar";
import Header from "../dashboard/header";
import { FaStar, FaUser, FaRegComments, FaCalendarAlt } from "react-icons/fa";

const ReviewPage = () => {
  const [reviews, setReviews] = useState([]);
  const [filter, setFilter] = useState("all");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const reviewsPerPage = 5;

  useEffect(() => {
    // Simulate fetching reviews from an API
    setLoading(true);
    setTimeout(() => {
      setReviews([
        { id: 1, name: "John Doe", rating: 4, comment: "Great course!", date: "2024-12-01" },
        { id: 2, name: "Jane Smith", rating: 5, comment: "Loved it!", date: "2024-11-28" },
        { id: 3, name: "Bob Johnson", rating: 3, comment: "Good course but too slow", date: "2024-11-25" },
        { id: 4, name: "Alice Williams", rating: 5, comment: "Amazing content and explanations.", date: "2024-12-05" },
        { id: 5, name: "David Lee", rating: 2, comment: "Not very useful for me.", date: "2024-12-02" },
        { id: 6, name: "Maria Garcia", rating: 4, comment: "Well structured and easy to follow.", date: "2024-11-30" },
        { id: 7, name: "Peter Brown", rating: 5, comment: "Highly recommend this course!", date: "2024-11-29" },
      ]);
      setLoading(false);
    }, 1000);
  }, []);

  const handleSort = (criteria) => {
    const sortedReviews = [...reviews];
    if (criteria === "rating") {
      sortedReviews.sort((a, b) => b.rating - a.rating);
    } else if (criteria === "date") {
      sortedReviews.sort((a, b) => new Date(b.date) - new Date(a.date));
    }
    setReviews(sortedReviews);
  };

  const handleFilter = (rating) => {
    setFilter(rating);
  };

  const currentReviews = reviews
    .filter((review) => (filter === "all" ? true : review.rating === parseInt(filter)))
    .slice((page - 1) * reviewsPerPage, page * reviewsPerPage);

  const totalPages = Math.ceil(reviews.length / reviewsPerPage);

  return (
    <div className="min-h-screen flex flex-col bg-gray-100">
      {/* Header */}
      <div className="fixed top-0 left-0 right-0 z-20">
        <Header />
      </div>

      {/* Layout */}
      <div className="flex flex-1 pt-16 md:pt-20 overflow-hidden">
        {/* Sidebar */}
        <div className="hidden md:block w-60 bg-white shadow-lg z-10 flex-shrink-0">
          <Sidebar />
        </div>

        {/* Main Content */}
        <div className="flex-1 flex flex-col p-4 sm:p-6 space-y-6 overflow-auto">
          {/* Page Header */}
          <div className="bg-[#EFD316] text-white p-6 rounded-lg shadow-md">
            <h1 className="text-2xl font-bold text-black">Trainee Reviews</h1>
            <p className="text-sm mt-1 text-black">Read reviews and feedback from your fellow trainees.</p>
          </div>

          {/* Filters and Sorting */}
          <div className="flex justify-between mb-6">
            <div className="flex items-center space-x-4">
              <button
                onClick={() => handleSort("rating")}
                className="px-4 py-2 rounded-lg bg-blue-500 text-white"
              >
                Sort by Rating
              </button>
              <button
                onClick={() => handleSort("date")}
                className="px-4 py-2 rounded-lg bg-green-500 text-white"
              >
                Sort by Date
              </button>
            </div>

            <div>
              <label htmlFor="ratingFilter" className="mr-2 text-gray-700">Filter by Rating:</label>
              <select
                id="ratingFilter"
                value={filter}
                onChange={(e) => handleFilter(e.target.value)}
                className="px-4 py-2 rounded-lg bg-gray-200"
              >
                <option value="all">All</option>
                <option value="5">5 Stars</option>
                <option value="4">4 Stars</option>
                <option value="3">3 Stars</option>
                <option value="2">2 Stars</option>
              </select>
            </div>
          </div>

          {/* Reviews Section */}
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-bold mb-4">Recent Reviews</h2>
            {loading ? (
              <div className="flex justify-center items-center">
                <div className="loader">Loading...</div>
              </div>
            ) : (
              <div className="space-y-6">
                {currentReviews.map((review) => (
                  <div key={review.id} className="border-b pb-4 mb-4">
                    <div className="flex items-center space-x-3">
                      <FaUser className="text-gray-500 text-xl" />
                      <div>
                        <h3 className="font-semibold text-gray-700">{review.name}</h3>
                        <p className="text-xs text-gray-500">{review.date}</p>
                      </div>
                    </div>
                    <div className="mt-2">
                      <div className="flex items-center space-x-1">
                        {Array.from({ length: review.rating }).map((_, index) => (
                          <FaStar key={index} className="text-yellow-400" />
                        ))}
                      </div>
                      <p className="mt-2 text-gray-600">{review.comment}</p>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* Pagination */}
          <div className="flex justify-center space-x-4">
            <button
              disabled={page === 1}
              onClick={() => setPage(page - 1)}
              className="px-4 py-2 rounded-lg bg-gray-500 text-white"
            >
              Previous
            </button>
            <span className="flex items-center justify-center text-xl font-semibold">
              Page {page} of {totalPages}
            </span>
            <button
              disabled={page === totalPages}
              onClick={() => setPage(page + 1)}
              className="px-4 py-2 rounded-lg bg-gray-500 text-white"
            >
              Next
            </button>
          </div>

          {/* Review Statistics */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
            <div className="bg-blue-100 p-4 rounded-lg text-center shadow-md">
              <FaUser className="text-blue-500 text-4xl mx-auto mb-2" />
              <h3 className="text-lg font-bold text-blue-500">Trainees</h3>
              <p className="text-2xl font-semibold">1,230</p>
            </div>
            <div className="bg-[#EFD316] p-4 rounded-lg text-center shadow-md">
              <FaRegComments className="text-yellow-500 text-4xl mx-auto mb-2" />
              <h3 className="text-lg font-bold text-[#EFD316]">Reviews</h3>
              <p className="text-2xl font-semibold">1,450</p>
            </div>
            <div className="bg-yellow-100 p-4 rounded-lg text-center shadow-md">
              <FaCalendarAlt className="text-yellow-500 text-4xl mx-auto mb-2" />
              <h3 className="text-lg font-bold text-yellow-500">Last Review</h3>
              <p className="text-2xl font-semibold">Dec 1, 2024</p>
            </div>
            <div className="bg-red-100 p-4 rounded-lg text-center shadow-md">
              <FaStar className="text-red-500 text-4xl mx-auto mb-2" />
              <h3 className="text-lg font-bold text-red-500">Average Rating</h3>
              <p className="text-2xl font-semibold">4.2 / 5</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewPage;

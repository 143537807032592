import React, { useState } from "react";
import Sidebar from "../dashboard/sidebar";
import Header from "../dashboard/header";
import { BsFlagFill } from "react-icons/bs";
import passport from "../images/PASSPORT.jpg";

const NotificationModule = () => {
  const [notifications, setNotifications] = useState([
    {
      id: 1,
      type: "message",
      sender: "Instructor",
      content: "Your assignment submission has been reviewed.",
      img: passport,
      timestamp: "5 mins ago",
      isRead: false,
      priority: "high",
    },
    {
      id: 2,
      type: "quiz",
      sender: "Quiz Notification",
      content: "You scored 85% on the React Basics Quiz!",
      img: null,
      timestamp: "2 hours ago",
      isRead: false,
      priority: "medium",
    },
    {
      id: 3,
      type: "message",
      sender: "Instructor",
      content: "Remember to join the live session tomorrow at 10 AM.",
      img: passport,
      timestamp: "1 day ago",
      isRead: true,
      priority: "low",
    },
  ]);

  const toggleReadStatus = (id) => {
    setNotifications((prev) =>
      prev.map((notification) =>
        notification.id === id
          ? { ...notification, isRead: !notification.isRead }
          : notification
      )
    );
  };

  return (
    <div className="min-h-screen flex flex-col bg-gray-100">
      {/* Header */}
      <div className="fixed top-0 left-0 right-0 z-20">
        <Header />
      </div>

      {/* Layout */}
      <div className="flex flex-1 pt-16 md:pt-20 overflow-hidden">
        {/* Sidebar */}
        <div className="hidden md:block w-60 bg-white shadow-lg z-10 flex-shrink-0">
          <Sidebar />
        </div>

        {/* Main Content */}
        <div className="flex-1 flex flex-col p-4 sm:p-6 space-y-6 overflow-auto">
          {/* Page Header */}
          <div className="bg-[#EFD316] text-white p-4 rounded-lg shadow-md">
            <h1 className="text-2xl text-black font-bold">Notifications Center</h1>
            <p className="text-sm mt-1 text-black">
              Stay updated with the latest messages and quiz results. You can
              manage your notifications here.
            </p>
          </div>

          {/* Summary Section */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
            <div className="bg-[#EFD316] p-4 rounded-lg text-center">
              <h3 className="text-lg font-bold text-gray-700">Unread</h3>
              <p className="text-2xl font-semibold">
                {notifications.filter((n) => !n.isRead).length}
              </p>
            </div>
            <div className="bg-green-100 p-4 rounded-lg text-center">
              <h3 className="text-lg font-bold text-green-500">Completed</h3>
              <p className="text-2xl font-semibold">12</p>
            </div>
          </div>

          {/* Notification List */}
          <div className="space-y-6">
            {notifications.map((notification) => (
              <div
                key={notification.id}
                className={`flex items-center bg-gray-50 rounded-lg p-4 shadow-inner ${
                  notification.priority === "high"
                    ? "border-l-4 border-red-500"
                    : notification.priority === "medium"
                    ? "border-l-4 border-yellow-500"
                    : ""
                }`}
              >
                {/* Notification Icon or Image */}
                {notification.img ? (
                  <img
                    src={notification.img}
                    alt={`${notification.sender}'s avatar`}
                    className="w-12 h-12 rounded-full object-cover border-2 border-gray-300"
                  />
                ) : (
                  <div className="w-12 h-12 flex items-center justify-center bg-[#EFD316] rounded-full">
                    <span className="text-gray-800 text-xl font-bold">Q</span>
                  </div>
                )}

                {/* Notification Content */}
                <div className="ml-4 flex-1">
                  <p className="font-semibold text-gray-700">
                    {notification.sender}
                  </p>
                  <p className="text-gray-600">{notification.content}</p>
                  <p className="text-sm text-gray-500 mt-1">
                    {notification.timestamp}
                  </p>
                </div>

                {/* Mark as Read/Unread */}
                <button
                  onClick={() => toggleReadStatus(notification.id)}
                  className={`text-2xl ${
                    notification.isRead ? "text-red-500" : "text-gray-400"
                  } hover:text-red-500`}
                >
                  <BsFlagFill />
                </button>
              </div>
            ))}
          </div>

          {/* Load More */}
          <button className="w-full bg-[#EFD316] text-gray-800 p-3 rounded-lg shadow hover:bg-yellow-400">
            Load More
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotificationModule;

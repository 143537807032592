import React, { useEffect, useState } from "react";
import {
    FaGraduationCap, FaCog, FaPen, FaTasks,
    FaChartLine, FaComments, FaBroadcastTower,
    FaComment,
    FaAward,
    FaQuestion,
    FaAccessibleIcon,
    FaIdBadge
} from 'react-icons/fa';
import CourseCreateModal from '../courses/CourseCreateModal';
import { FaBookBookmark, FaBookOpen, FaComputer, FaMessage } from 'react-icons/fa6';
import { useNavigate } from "react-router-dom";
import axios from "axios";



const Sidebar = () => {
    const [isOpen, setIsOpen] = useState(true);
    const [expanded, setExpanded] = useState(null); // For expanding/collapsing modules
    const [selected, setSelected] = useState(null); // For active item highlight
    const [showModal, setShowModal] = useState(false); // Modal visibility state


    /* fetch role from user database */
    const [fullName, setFullName] = useState(""); // State for full name
    const [userRole, setUserRole] = useState(""); // State for user role
    const [error, setError] = useState(""); // State for error messages
    useEffect(() => {
        const storedFullName = localStorage.getItem("fullName"); // Retrieve full name from localStorage
        if (storedFullName) {
            setFullName(storedFullName);
            // Fetch user role based on full name
            axios
                .get(`https://swiftsendify.com/learning_api/fetchRole.php?fullName=${storedFullName}`) // Replace with your API endpoint
                .then((response) => {
                    if (response.data.status === "success") {
                        setUserRole(response.data.role); // Assuming `role` is returned in the API response
                    } else {
                        setError("Failed to fetch user role");
                    }
                })
                .catch((err) => {
                    console.error("Error fetching user role:", err);
                    setError("An error occurred while fetching user role.");
                });
        }
    }, []);
    //const userName = fullName || "Guest"; // Display full name or default if not available
    const role = userRole || "Fetching role..."; // Display loading or default if not available



    // Toggle sidebar open/close
    const toggleSidebar = () => setIsOpen(!isOpen);

    // Handle item click: set active and uncollapse if needed
    const handleItemClick = (index) => {
        setSelected(index);
        if (!isOpen) setIsOpen(true); // Uncollapse sidebar if collapsed
    };

    // Handle module expansion
    const handleExpand = (index) => {
        setExpanded(expanded === index ? null : index); // Toggle expansion
    };


    const navigate = useNavigate();
    const handleManageCourse = () => {
        navigate("/manage-course");
    };
    const handleProgressTracking = () => {
        navigate("/Progress-tracking");
    };
    const handleQuizCreation = () => {
        navigate("/quiz-creation");
    };
    const handleAutomaticGrading = () => {
        navigate("/grading");
    };
    const handleOverallVIew = () => {
        navigate("/overall-view");
    };
    const handleDiscussion = () => {
        navigate("/interaction-blog");
    };
    const handleNotification = () => {
        navigate("/notification");
    };
    const handleCourseCompletion = () => {
        navigate("/course-completion");
    };
    const handleAnalytics = () => {
        navigate("/analytics");
    };
    const handleReview = () => {
        navigate("/reviews");
    };
    const handleSetReview = () => {
        navigate("/set-reviews");
    };
    const handleUploadQuestions = () => {
        navigate("/upload-question");
    };
    const handleExamList = () => {
        navigate("/exam-question");
    };
    const handleDownloadResult = () => {
        navigate("/download-result");
    };



    return (
        <div
            className={`h-full ${isOpen ? 'w-64' : 'w-20'} bg-[#0a2e3e] text-white transition-all duration-300 flex flex-col justify-between`}
        >


            {/* Top Menu Section */}
            <div className="mt-6 flex flex-col space-y-4">
                <p><span className="font-semibold">Your Role:</span> {role}</p>
                {/* Courses Management */}
                <div>
                    {role !== 'Student' && (
                        <button
                            className={`flex items-center p-3 rounded-lg space-x-3 cursor-pointer ${selected === 0
                                ? 'bg-[#EFD316] text-[#0a2e3e]'
                                : 'hover:bg-[#EFD316] hover:text-black'
                                }`}
                            onClick={() => {
                                handleItemClick(0);
                                setExpanded(expanded === 0 ? null : 0);
                            }}
                        >
                            <FaBookOpen size={24} />
                            {isOpen && <span> Module </span>}
                        </button>
                    )}


                    {/* Sub-items */}
                    {expanded === 0 && (
                        <div className="pl-6 mt-2 space-y-2">
                            <button
                                className="flex items-center p-2 text-sm rounded-lg hover:bg-[#EFD316] hover:text-black"
                                onClick={() => setShowModal(true)} // Show the modal
                            >
                                <FaPen size={16} className="mr-2" /> Modules Creation
                            </button>
                            <button
                                className="flex items-center p-2 text-sm rounded-lg hover:bg-[#EFD316] hover:text-black"
                                onClick={() => {
                                    setSelected(0); // Keep the section highlighted
                                    handleManageCourse();
                                }}
                            >
                                <FaBookOpen size={16} className="mr-2" /> Manage Modules
                            </button>
                            <button
                                className="flex items-center p-2 text-sm rounded-lg hover:bg-[#EFD316] hover:text-black"
                                onClick={() => {
                                    setSelected(0); // Keep the section highlighted
                                    handleProgressTracking();
                                }}
                            >
                                <FaChartLine size={16} className="mr-2" /> Progress Tracking
                            </button>
                        </div>
                    )}
                </div>


                {/* Quiz and Tests */}
                <div>
                    {role !== 'Student' && (
                        <button
                            className={`flex items-center p-3 rounded-lg space-x-3 cursor-pointer ${selected === 1 ? 'bg-[#EFD316] text-[#0a2e3e]' : 'hover:bg-[#EFD316] hover:text-black'}`}
                            onClick={() => {
                                handleItemClick(1);
                                handleExpand(1);
                            }}
                        >
                            <FaGraduationCap size={24} />
                            {isOpen && <span>Quiz And Tests</span>}
                        </button>
                    )}

                    {/* Sub-items */}
                    {expanded === 1 && (
                        <div className="pl-6 mt-2 space-y-2">
                            <button className="flex items-center p-2 text-sm rounded-lg hover:bg-[#EFD316] hover:text-black" onClick={() => {
                                setSelected(0); // Keep the section highlighted
                                handleQuizCreation();
                            }}>
                                <FaPen size={16} className="mr-2" /> Quiz Creation
                            </button>
                            <button className="flex items-center p-2 text-sm rounded-lg hover:bg-[#EFD316] hover:text-black" onClick={() => {
                                setSelected(0); // Keep the section highlighted
                                handleAutomaticGrading();
                            }}>
                                <FaTasks size={16} className="mr-2" /> Automatic Grading
                            </button>
                            <button className="flex items-center p-2 text-sm rounded-lg hover:bg-[#EFD316] hover:text-black" onClick={() => {
                                setSelected(0); // Keep the section highlighted
                                handleOverallVIew();
                            }}>
                                <FaBookBookmark size={16} className="mr-2" /> Overall View Grading
                            </button>
                        </div>
                    )}
                </div>

                {/* Interaction Module */}
                <div>
                    {role !== 'Student' && (
                        <button
                            className={`flex items-center p-3 rounded-lg space-x-3 cursor-pointer ${selected === 2 ? 'bg-[#EFD316] text-[#0a2e3e]' : 'hover:bg-[#EFD316] hover:text-black'
                                }`}
                            onClick={() => {
                                handleItemClick(2);
                                handleExpand(2);
                            }}
                        >
                            <FaComment size={24} />
                            {isOpen && <span>Interaction Module</span>}
                        </button>
                    )}
                    {/* Sub-items */}
                    {expanded === 2 && (
                        <div className="pl-6 mt-2 space-y-2">
                            <button className="flex items-center p-2 text-sm rounded-lg hover:bg-[#EFD316] hover:text-black" onClick={() => {
                                setSelected(2); // Keep the section highlighted
                                handleDiscussion();
                            }}>
                                <FaComments size={16} className="mr-2" /> Discussions
                            </button>
                            <button className="flex items-center p-2 text-sm rounded-lg hover:bg-[#EFD316] hover:text-black" onClick={() => {
                                setSelected(2); // Keep the section highlighted
                                handleNotification();
                            }}>
                                <FaBroadcastTower size={16} className="mr-2" /> Notifications
                            </button>
                        </div>
                    )}
                </div>

                {/* Student Progress Tracking */}
                <div>
                    {role !== 'Student' && (
                        <button
                            className={`flex items-center p-3 rounded-lg space-x-3 cursor-pointer ${selected === 2 ? 'bg-[#EFD316] text-[#0a2e3e]' : 'hover:bg-[#EFD316] hover:text-black'
                                }`}
                            onClick={() => {
                                handleItemClick(2);
                                handleExpand(2);
                            }}
                        >
                            <FaComment size={24} />
                            {isOpen && <span>Interaction Module</span>}
                        </button>
                    )}

                    {/* Sub-items for Student Progress */}
                    {expanded === 3 && (
                        <div className="pl-6 space-y-2 mt-2">
                            <button className="flex items-center p-2 text-sm rounded-lg hover:bg-[#EFD316] hover:text-black" onClick={() => {
                                handleItemClick(3);
                                handleCourseCompletion(3);
                            }}>
                                <FaChartLine size={16} className="mr-2" /> Course Completion
                            </button>
                            <button className="flex items-center p-2 text-sm rounded-lg hover:bg-[#EFD316] hover:text-black" onClick={() => {
                                handleItemClick(3);
                                handleAnalytics(3);
                            }}>
                                <FaChartLine size={16} className="mr-2" /> Analytics
                            </button>
                        </div>
                    )}
                </div>

                {/* Broadcast Module */}
                <div>
                    <button
                        className={`flex items-center p-3 rounded-lg space-x-3 cursor-pointer ${role === 'Student' ? 'invisible pointer-events-none' : selected === 4
                            ? 'bg-[#EFD316] text-[#0a2e3e]'
                            : 'hover:bg-[#EFD316] hover:text-black'
                            }`}
                        onClick={() => {
                            handleItemClick(4);
                            handleExpand(4);
                        }}
                    >
                        <FaAward size={24} />
                        {isOpen && <span>Certification</span>}
                    </button>

                    {/* Sub-items for Broadcast */}
                    {expanded === 4 && (
                        <div className="pl-6 space-y-2 mt-2">
                            <button className="flex items-center p-2 text-sm rounded-lg hover:bg-[#EFD316] hover:text-black" onClick={() => {
                                handleItemClick(4);
                                handleSetReview(4);
                            }}>
                                <FaMessage size={16} className="mr-2" /> Reviews
                            </button>
                            <button className="flex items-center p-2 text-sm rounded-lg hover:bg-[#EFD316] hover:text-black" onClick={() => {
                                handleItemClick(4);
                                handleReview(4);
                            }}>
                                <FaComments size={16} className="mr-2" /> Manage Reviews
                            </button>
                        </div>
                    )}
                </div>

                {/* CBT Module */}
                <div>
                    <button
                        className={`flex items-center p-3 rounded-lg space-x-3 cursor-pointer ${selected === 5 ? 'bg-[#EFD316] text-[#0a2e3e]' : 'hover:bg-[#EFD316] hover:text-black'
                            }`}
                        onClick={() => {
                            handleItemClick(5);
                            handleExpand(5);
                        }}
                    >
                        <FaComputer size={24} />
                        {isOpen && <span>CBT</span>}
                    </button>
                    {/* Sub-items for Broadcast */}
                    {expanded === 5 && (
                        <div className="pl-6 space-y-2 mt-2">
                            {role !== 'Student' && (
                                <button
                                    className="flex items-center p-2 text-sm rounded-lg hover:bg-[#EFD316] hover:text-black"
                                    onClick={() => {
                                        handleItemClick(5);
                                        handleUploadQuestions(5);
                                    }}
                                >
                                    <FaQuestion size={16} className="mr-2" /> Upload Questions
                                </button>
                            )}

                            <button className="flex items-center p-2 text-sm rounded-lg hover:bg-[#EFD316] hover:text-black" onClick={() => {
                                handleItemClick(5);
                                handleExamList(5);
                            }}>
                                <FaAccessibleIcon size={16} className="mr-2" /> Exam Questions
                            </button>
                            {role !== 'Student' && (
                                <button
                                    className="flex items-center p-2 text-sm rounded-lg hover:bg-[#EFD316] hover:text-black"
                                    onClick={() => {
                                        handleItemClick(5);
                                        handleDownloadResult(5);
                                    }}
                                >
                                    <FaIdBadge size={16} className="mr-2" /> Student Result
                                </button>
                            )}

                        </div>
                    )}
                </div>
            </div>

            {/* Bottom Section (e.g., Settings / Collapse Button) */}
            <div className="mb-6 flex justify-center">
                <button
                    className="w-12 h-12 bg-[#EFD316] rounded-full flex items-center justify-center hover:bg-[#D4BC0D]"
                    onClick={toggleSidebar}
                >
                    <FaCog size={24} />
                </button>
            </div>
            {showModal && <CourseCreateModal onClose={() => setShowModal(false)} />} {/* Render modal */}
        </div>
    );
};

export default Sidebar;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FaBook, FaCogs, FaClock, FaUsers } from "react-icons/fa";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { BsFillPlayFill } from "react-icons/bs";
import { FiMoreVertical } from "react-icons/fi";
import { FaSpinner } from "react-icons/fa"; // Spinner Icon for the Progress Circle



function Overview() {
    const coursesProgress = 45;
    const prototypesProgress = 80;

    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(true);

    // Function to fetch courses from the backend
    const fetchCourses = async () => {
        try {
            const response = await axios.get('https://swiftsendify.com/learning_api/get_courses.php');
            if (response.data.status === 'success') {
                setCourses(response.data.courses);
            } else {
                console.error('Failed to fetch courses:', response.data.message);
            }
        } catch (error) {
            console.error('Error fetching courses:', error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch courses when component mounts and set interval for auto-refresh
    useEffect(() => {
        fetchCourses(); // Initial fetch

        const intervalId = setInterval(fetchCourses, 5000); // Auto reload every 5 seconds

        // Clean up the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, []);

    if (loading) {
        return (
            <div className="flex items-center justify-center h-screen">
                <FaSpinner className="animate-spin text-[#EFD316] text-4xl" />
            </div>
        );
    }

    return (
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
            {/* Overview Cards */}
            <div className="col-span-1 lg:col-span-3 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                <div className="p-4 bg-white shadow-sm rounded-lg flex flex-col items-center">
                    <div className="mb-2 text-[#EFD316]">
                        <FaBook size={24} />
                    </div>
                    <h3 className="text-sm font-semibold text-gray-600">Courses in Progress</h3>
                    <p className="text-2xl font-bold text-gray-800">3</p>
                </div>
                <div className="p-4 bg-white shadow-sm rounded-lg flex flex-col items-center">
                    <div className="mb-2 text-[#EFD316]">
                        <FaCogs size={24} />
                    </div>
                    <h3 className="text-sm font-semibold text-gray-600">Active Prototypes</h3>
                    <p className="text-2xl font-bold text-gray-800">7</p>
                </div>
                <div className="p-4 bg-white shadow-sm rounded-lg flex flex-col items-center">
                    <div className="mb-2 text-[#EFD316]">
                        <FaClock size={24} />
                    </div>
                    <h3 className="text-sm font-semibold text-gray-600">Hours Learning</h3>
                    <p className="text-2xl font-bold text-gray-800">3h 15m</p>
                </div>
                <div className="p-4 bg-white shadow-sm rounded-lg flex flex-col items-center">
                    <div className="mb-2 text-[#EFD316]">
                        <FaUsers size={24} />
                    </div>
                    <h3 className="text-sm font-semibold text-gray-600">Community Score</h3>
                    <p className="text-2xl font-bold text-gray-800">240</p>
                </div>
            </div>


            {/* Study Statistics */}
            <div className="p-6 bg-white shadow-md rounded-lg">
                <h3 className="text-lg font-semibold text-[#EFD316] mb-4">
                    Study Statistics
                </h3>
                <div className="flex justify-between items-center">
                    <button className="text-sm text-gray-500 border rounded-md px-2 py-1 hover:bg-gray-100">
                        Week
                    </button>
                    <button className="text-sm text-gray-500 border rounded-md px-2 py-1 hover:bg-gray-100">
                        Month
                    </button>
                </div>
                <div className="mt-6">
                    {/* Bar Chart */}
                    <div className="flex items-end space-x-4 h-40">
                        <div className="flex flex-col items-center">
                            <div className="w-8 bg-[#EFD316] h-10 rounded-t-md"></div>
                            <span className="text-sm text-gray-500 mt-1">Sat</span>
                        </div>
                        <div className="flex flex-col items-center">
                            <div className="w-8 bg-[#8ED1FC] h-20 rounded-t-md"></div>
                            <span className="text-sm text-gray-500 mt-1">Sun</span>
                        </div>
                        <div className="flex flex-col items-center">
                            <div className="w-8 bg-[#EFD316] h-24 rounded-t-md"></div>
                            <span className="text-sm text-gray-500 mt-1">Mon</span>
                        </div>
                        <div className="flex flex-col items-center">
                            <div className="w-8 bg-[#5DADEC] h-24 rounded-t-md"></div>
                            <span className="text-sm text-gray-500 mt-1">Tue</span>
                        </div>
                        <div className="flex flex-col items-center">
                            <div className="w-8 bg-[#EFD316] h-36 rounded-t-md"></div>
                            <span className="text-sm text-gray-500 mt-1">Wed</span>
                        </div>
                        <div className="flex flex-col items-center">
                            <div className="w-8 bg-[#5DADEC] h-24 rounded-t-md"></div>
                            <span className="text-sm text-gray-500 mt-1">Thu</span>
                        </div>
                        <div className="flex flex-col items-center">
                            <div className="w-8 bg-[#EFD316] h-16 rounded-t-md"></div>
                            <span className="text-sm text-gray-500 mt-1">Fri</span>
                        </div>
                    </div>
                </div>
            </div>

            {/* Progress */}
            <div className="p-6 bg-white shadow-md rounded-lg">
                <h3 className="text-lg font-semibold text-[#EFD316] mb-4">
                    Progress
                </h3>
                <div className="flex justify-center items-center gap-8">
                    {/* Courses Progress */}
                    <div className="w-24 h-24">
                        <CircularProgressbar
                            value={coursesProgress}
                            text={`${coursesProgress}%`}
                            styles={buildStyles({
                                textColor: "#EFD316",
                                pathColor: "#EFD316",
                                trailColor: "#E4E4E4",
                            })}
                        />
                        <p className="text-center text-sm text-gray-500 mt-2">
                            Courses
                        </p>
                    </div>

                    {/* Prototypes Progress */}
                    <div className="w-24 h-24">
                        <CircularProgressbar
                            value={prototypesProgress}
                            text={`${prototypesProgress}%`}
                            styles={buildStyles({
                                textColor: "#EFD316",
                                pathColor: "#EFD316",
                                trailColor: "#E4E4E4",
                            })}
                        />
                        <p className="text-center text-sm text-gray-500 mt-2">
                            Prototypes
                        </p>
                    </div>
                </div>
            </div>

            <div className="p-6 bg-white shadow-md rounded-lg">
                <h3 className="text-lg font-semibold text-[#EFD316] mb-4">Progress</h3>
                <div className="flex items-center justify-center">
                    <div className="relative w-24 h-24">
                        <svg className="w-full h-full">
                            <circle
                                cx="50%"
                                cy="50%"
                                r="40%"
                                className="stroke-gray-300 fill-none"
                                strokeWidth="8"
                            />
                            <circle
                                cx="50%"
                                cy="50%"
                                r="40%"
                                className="stroke-[#EFD316] fill-none"
                                strokeWidth="8"
                                strokeDasharray="81"
                                strokeDashoffset=""
                            />
                        </svg>
                        <div className="absolute inset-0 flex items-center justify-center text-lg font-bold text-gray-700">
                            45%
                        </div>
                    </div>
                </div>
            </div>

            {/* My Courses */}
            <div className="lg:col-span-3">
                {/* Section Title */}
                <h3 className="text-lg font-semibold text-gray-800 mb-6">My Modules</h3>

                {/* Courses Grid */}
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-6">
                    {courses.map((course) => (
                        <div
                            key={course.id}
                            className="p-6 bg-[#F7F3FE] shadow-md rounded-lg"
                        >
                            <div className="flex justify-between items-start mb-4">
                                <span className="text-[#EFD316] text-xl">
                                    <BsFillPlayFill />
                                </span>
                                <button className="text-gray-500 hover:text-gray-700 text-2xl">
                                    <FiMoreVertical />
                                </button>
                            </div>
                            <h4 className="text-base font-semibold text-gray-800 mb-2">
                                {course.course_name}
                            </h4>
                            <p className="text-sm text-gray-500 mb-4">
                                {course.description}
                            </p>

                            {/* Circular Progress (React Icon based) */}
                            <div className="relative w-16 h-16 mx-auto">
                                <FaSpinner className="animate-spin text-[#EFD316] w-full h-full" />
                            </div>
                        </div>
                    ))}
                </div>

            </div>

        </div>
    );
}

export default Overview;

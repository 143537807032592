import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FiSearch, FiBell, FiSettings, FiMenu, FiX } from "react-icons/fi";
import SignUp from "../signup";
import axios from "axios";

function Header() {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);

  const [fullName, setFullName] = useState(""); // State for full name
  const navigate = useNavigate();

  // Fetch user's full name from localStorage on component mount
  useEffect(() => {
    const storedFullName = localStorage.getItem("fullName");
    if (storedFullName) {
      setFullName(storedFullName);
    }
  }, []);

  const toggleSettingsDropdown = () => {
    setIsSettingsOpen((prev) => !prev);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prev) => !prev);
  };

  const openSignUpModal = () => {
    setIsSignUpModalOpen(true);
  };

  const closeSignUpModal = () => {
    setIsSignUpModalOpen(false);
  };

  const handleSignOut = () => {
    localStorage.removeItem("fullName");
    navigate("/");
  };

  const navigateToDashboard = () => {
    navigate("/dashboard");
  };



  const [userRole, setUserRole] = useState(""); // State for user role
  const [error, setError] = useState(""); // State for error messages
  useEffect(() => {
    const storedFullName = localStorage.getItem("fullName"); // Retrieve full name from localStorage
    if (storedFullName) {
      setFullName(storedFullName);
      // Fetch user role based on full name
      axios
        .get(`https://swiftsendify.com/learning_api/fetchRole.php?fullName=${storedFullName}`) // Replace with your API endpoint
        .then((response) => {
          if (response.data.status === "success") {
            setUserRole(response.data.role); // Assuming `role` is returned in the API response
          } else {
            setError("Failed to fetch user role");
          }
        })
        .catch((err) => {
          console.error("Error fetching user role:", err);
          setError("An error occurred while fetching user role.");
        });
    }
  }, []);
  const userName = fullName || "Guest"; // Display full name or default if not available
  const role = userRole || "Fetching role..."; // Display loading or default if not available


  return (
    <header className="flex items-center justify-between p-4 bg-white shadow-md w-full">
      {/* Left Section */}
      <div className="flex items-center space-x-4">
        <button
          onClick={toggleMobileMenu}
          className="text-gray-700 sm:hidden focus:outline-none"
        >
          {isMobileMenuOpen ? <FiX size={24} /> : <FiMenu size={24} />}
        </button>
        <h2
          className="text-xl sm:text-2xl font-bold cursor-pointer text-gray-700"
          onClick={navigateToDashboard}
        >
          Mercy Medical University Dashboard
        </h2>
      </div>

      {/* Right Section */}
      <div className="flex items-center space-x-4">
        <button className="text-gray-500 hover:text-yellow-500">
          <FiSearch size={20} />
        </button>
        <button className="text-gray-500 hover:text-yellow-500 relative">
          <FiBell size={20} />
          <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs w-4 h-4 flex items-center justify-center rounded-full">
            3
          </span>
        </button>
        <div className="relative">
          <button
            className="text-gray-500 hover:text-yellow-500"
            onClick={toggleSettingsDropdown}
          >
            <FiSettings size={20} />
          </button>
          {isSettingsOpen && (
            <ul className="absolute right-0 mt-3 w-48 bg-white border border-gray-200 rounded-md shadow-lg z-50">
              <li className="px-4 py-2 text-gray-800 border-b flex items-center space-x-2">
                <div>
                  <span className="text-sm text-gray-600">
                    <strong>{fullName || "User"}</strong>
                  </span>
                </div>
              </li>
              <li>
                <button
                  onClick={handleSignOut}
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100 w-full text-center"
                >
                  Sign Out
                </button>
              </li>
              <li>
                {role !== "Student" && (
                  <li>
                    <button
                      onClick={openSignUpModal}
                      className="block px-4 py-2 text-gray-700 hover:bg-gray-100 w-full text-center"
                    >
                      Register User
                    </button>
                  </li>
                )}
              </li>
            </ul>
          )}
        </div>
      </div>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="absolute top-0 left-0 w-full h-screen bg-white z-40 flex flex-col p-4">
          <button
            onClick={toggleMobileMenu}
            className="self-end text-gray-500 hover:text-yellow-500 mb-4"
          >
            <FiX size={24} />
          </button>
          <nav className="flex flex-col space-y-4">
            <Link
              to="/dashboard"
              className="text-gray-700 hover:text-yellow-500"
              onClick={toggleMobileMenu}
            >
              Dashboard
            </Link>
            <Link
              to="/profile"
              className="text-gray-700 hover:text-yellow-500"
              onClick={toggleMobileMenu}
            >
              My Profile
            </Link>
            <button
              onClick={handleSignOut}
              className="text-gray-700 hover:text-yellow-500 text-left"
            >
              Sign Out
            </button>
          </nav>
        </div>
      )}

      {/* Sign Up Modal */}
      {isSignUpModalOpen && <SignUp closeModal={closeSignUpModal} />}
    </header>
  );
}

export default Header;

import React, { useState } from "react";
import * as mammoth from "mammoth";
import { toast, ToastContainer } from 'react-toastify'; // Correct import for toast
import Sidebar from "../dashboard/sidebar";
import Header from "../dashboard/header";
import 'react-toastify/dist/ReactToastify.css'; // Ensure to include the CSS for toast notifications

const UploadQuestions = ({ onQuestionsAdded }) => {
    const [questions, setQuestions] = useState([]);
    const [file, setFile] = useState(null);

    const handleFileUpload = (e) => {
        const uploadedFile = e.target.files[0];
        setFile(uploadedFile);

        if (
            uploadedFile?.type ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ) {
            const reader = new FileReader();
            reader.onload = (event) => {
                mammoth
                    .extractRawText({ arrayBuffer: event.target.result })
                    .then((result) => {
                        const lines = result.value.split("\n").filter((line) => line.trim());
                        const parsedQuestions = lines
                            .map((line) => {
                                const parts = line.split(",");
                                return parts.length === 6
                                    ? {
                                        question: parts[0],
                                        options: [parts[1], parts[2], parts[3], parts[4]],
                                        correctOption: parts[5].toLowerCase(),
                                    }
                                    : null;
                            })
                            .filter(Boolean);

                        setQuestions(parsedQuestions);
                        onQuestionsAdded(parsedQuestions); // Callback function
                    })
                    .catch(() => toast.success("Checking the DOCX file."));
            };
            reader.readAsArrayBuffer(uploadedFile);
        } else {
            toast.error("Please upload a valid DOCX file.");
        }
    };


    const handleSaveQuestions = () => {
        if (questions.length === 0) {
            toast.error("No questions to save.");
            return;
        }

        if (!file) {
            toast.error("No file uploaded.");
            return;
        }

        const fileName = file.name;

        fetch("https://swiftsendify.com/learning_api/uploadQuestions.php", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                questions,
                fileName
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.status === "success") {
                    toast.success("Questions added successfully!");
                } else if (data.status === "error" && data.message.includes("Duplicate questions")) {
                    toast.error("Duplicate questions detected: " + data.message);
                } else {
                    toast.error(data.message || "Failed to add questions.");
                }
            })
            .catch((error) => {
                console.error("Error:", error);
                toast.error("Error uploading questions.");
            });
    };


    return (
        <div className="min-h-screen flex flex-col bg-gray-100">
            <div className="fixed top-0 left-0 right-0 z-20">
                <Header />
            </div>

            <div className="flex flex-1 pt-16 md:pt-20">
                <div className="hidden md:block w-64 bg-white shadow-lg z-10">
                    <Sidebar />
                </div>

                <div className="flex-1 p-4 md:p-6 lg:p-8 pt-20 md:pt-24">
                    <div className="bg-white shadow-md rounded-lg p-6">
                        <h2 className="text-2xl font-bold mb-4">Upload Questions</h2>
                        <input
                            type="file"
                            accept=".docx"
                            onChange={handleFileUpload}
                            className="mb-4"
                        />
                        {file && <p>Uploaded File: {file.name}</p>}
                        <button
                            onClick={handleSaveQuestions}
                            className="bg-[#EFD316] text-black px-4 py-2 rounded mt-4"
                        >
                            Save Questions
                        </button>
                    </div>

                    <div className="mt-8">
                        <h2 className="text-xl font-semibold mb-4">Parsed Questions</h2>
                        {questions.length === 0 ? (
                            <p className="text-gray-500">No questions parsed yet.</p>
                        ) : (
                            <ul className="space-y-4">
                                {questions.map((q, index) => (
                                    <li key={index} className="border-b border-gray-200 pb-4">
                                        <p className="font-semibold">{q.question}</p>
                                        <ul className="list-disc pl-5 mt-2">
                                            {q.options.map((option, idx) => (
                                                <li key={idx}>{option}</li>
                                            ))}
                                        </ul>
                                        <p className="mt-2 text-sm text-gray-600">
                                            Correct Answer: {q.correctOption}
                                        </p>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </div>
            </div>

            <ToastContainer /> {/* Add ToastContainer here for the toast notifications */}
        </div>
    );
};

export default UploadQuestions;
import React, { useState } from "react";
import Sidebar from "../dashboard/sidebar";
import Header from "../dashboard/header";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement } from "chart.js";
import * as mammoth from "mammoth";

// Register chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend);

const QuizCreation = () => {
    // State for holding questions and answers
    const [questions, setQuestions] = useState([]);
    const [newQuestion, setNewQuestion] = useState("");
    const [options, setOptions] = useState({ option1: "", option2: "", option3: "", option4: "" });
    const [correctOption, setCorrectOption] = useState("");
    const [file, setFile] = useState(null);
    const [previewOpen, setPreviewOpen] = useState(false);

    // Function to handle new question creation
    const handleAddQuestion = () => {
        if (newQuestion && options.option1 && options.option2 && options.option3 && options.option4 && correctOption) {
            setQuestions([
                ...questions,
                {
                    question: newQuestion,
                    options: [options.option1, options.option2, options.option3, options.option4],
                    correctOption,
                },
            ]);
            // Reset form
            setNewQuestion("");
            setOptions({ option1: "", option2: "", option3: "", option4: "" });
            setCorrectOption("");
        } else {
            alert("Please fill all fields before submitting.");
        }
    };

    // Function to handle file upload for bulk questions
    const handleFileUpload = (e) => {
        const uploadedFile = e.target.files[0];
        setFile(uploadedFile);

        // Ensure the uploaded file is a DOCX file
        if (uploadedFile && uploadedFile.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
            const reader = new FileReader();
            reader.onload = (event) => {
                const arrayBuffer = event.target.result;

                // Use Mammoth to extract text from DOCX file
                mammoth
                    .extractRawText({ arrayBuffer })
                    .then((result) => {
                        const text = result.value.split("\n").filter((line) => line.trim() !== ""); // Remove empty lines

                        // Process each line to extract questions and answers
                        const newQuestions = text
                            .map((line, idx) => {
                                const parts = line.split(",").map((part) => part.trim());
                                if (parts.length === 6) {
                                    const [question, option1, option2, option3, option4, correctOption] = parts;
                                    return {
                                        question: `Question ${idx + 1}: ${question}`,
                                        options: [option1, option2, option3, option4],
                                        correctOption: correctOption.toLowerCase(), // Ensure it's in lowercase
                                    };
                                } else {
                                    console.warn(`Invalid format on line ${idx + 1}: ${line}`);
                                    return null;
                                }
                            })
                            .filter((item) => item !== null); // Remove invalid entries

                        // Add the new questions to the existing questions state
                        setQuestions((prevQuestions) => [...prevQuestions, ...newQuestions]);
                    })
                    .catch((err) => {
                        console.error("Error processing DOCX file:", err);
                        alert("Failed to process DOCX file. Please ensure it's formatted correctly.");
                    });
            };
            reader.readAsArrayBuffer(uploadedFile);
        } else {
            alert("Please upload a valid DOCX file.");
        }
    };



    // Function to handle the preview modal
    const handlePreview = () => {
        setPreviewOpen(true);
    };

    const closePreview = () => {
        setPreviewOpen(false);
    };

    return (
        <div className="min-h-screen flex flex-col bg-gray-100">
            {/* Header */}
            <div className="fixed top-0 left-0 right-0 z-20">
                <Header />
            </div>

            {/* Layout */}
            <div className="flex flex-1 pt-16 md:pt-20">
                {/* Sidebar */}
                <div className="hidden md:block w-64 bg-white shadow-lg z-10">
                    <Sidebar />
                </div>

                {/* Main Content */}
                <div className="flex-1 p-4 md:p-6 lg:p-8 pt-20 md:pt-24">
                    <div className="p-6 space-y-8">
                        {/* Quiz Creation Form */}
                        <div className="bg-white shadow-md rounded-lg p-6">
                            <h2 className="text-2xl font-bold mb-4">Create a New Quiz</h2>
                            <div className="space-y-4">
                                <div className="flex flex-col">
                                    <label className="text-sm text-gray-700">Question:</label>
                                    <input
                                        type="text"
                                        className="border border-gray-300 rounded-lg p-2 mt-1"
                                        value={newQuestion}
                                        onChange={(e) => setNewQuestion(e.target.value)}
                                        placeholder="Enter the question"
                                    />
                                </div>
                                <div className="space-y-2">
                                    <label className="text-sm text-gray-700">Options:</label>
                                    <div className="grid grid-cols-2 gap-4">
                                        <input
                                            type="text"
                                            className="border border-gray-300 rounded-lg p-2"
                                            placeholder="Option 1"
                                            value={options.option1}
                                            onChange={(e) => setOptions({ ...options, option1: e.target.value })}
                                        />
                                        <input
                                            type="text"
                                            className="border border-gray-300 rounded-lg p-2"
                                            placeholder="Option 2"
                                            value={options.option2}
                                            onChange={(e) => setOptions({ ...options, option2: e.target.value })}
                                        />
                                        <input
                                            type="text"
                                            className="border border-gray-300 rounded-lg p-2"
                                            placeholder="Option 3"
                                            value={options.option3}
                                            onChange={(e) => setOptions({ ...options, option3: e.target.value })}
                                        />
                                        <input
                                            type="text"
                                            className="border border-gray-300 rounded-lg p-2"
                                            placeholder="Option 4"
                                            value={options.option4}
                                            onChange={(e) => setOptions({ ...options, option4: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-col">
                                    <label className="text-sm text-gray-700">Correct Option:</label>
                                    <select
                                        className="border border-gray-300 rounded-lg p-2 mt-1"
                                        value={correctOption}
                                        onChange={(e) => setCorrectOption(e.target.value)}
                                    >
                                        <option value="">Select Correct Option</option>
                                        <option value="option1">Option 1</option>
                                        <option value="option2">Option 2</option>
                                        <option value="option3">Option 3</option>
                                        <option value="option4">Option 4</option>
                                    </select>
                                </div>
                                <button
                                    onClick={handleAddQuestion}
                                    className="text-black py-2 px-4 rounded-lg mt-4 bg-[#EFD316]"
                                >
                                    Add Question
                                </button>
                            </div>
                        </div>

                        {/* Question List */}
                        <div className="bg-white shadow-md rounded-lg p-6 mt-6">
                            <h2 className="text-2xl font-bold mb-4">Added Questions</h2>
                            {questions.length === 0 ? (
                                <p className="text-gray-500">No questions added yet.</p>
                            ) : (
                                <ul className="space-y-4">
                                    {questions.map((q, index) => (
                                        <li key={index} className="border-b border-gray-200 pb-4">
                                            <p className="font-semibold">{q.question}</p>
                                            <ul className="list-disc pl-5 mt-2">
                                                {q.options.map((option, idx) => (
                                                    <li key={idx}>{option}</li>
                                                ))}
                                            </ul>
                                            <p className="mt-2 text-sm text-gray-600">
                                                Correct Answer: {q.correctOption}
                                            </p>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>

                        {/* File Upload Section */}
                        <div className="bg-white shadow-md rounded-lg p-6 mt-6">
                            <h2 className="text-2xl font-bold mb-4">Upload Questions from DOCX File</h2>
                            <input type="file" accept=".docx" onChange={handleFileUpload} className="mb-4" />
                            {file && <p>File uploaded: {file.name}</p>}
                        </div>

                        {/* Preview Button */}
                        <div className="flex justify-center mt-6">
                            <button
                                onClick={handlePreview}
                                className="bg-[#EFD316] text-black py-2 px-4 rounded-lg"
                            >
                                Preview Questions
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Preview Modal */}
            {previewOpen && (
                <div className="fixed inset-0 bg-gray-800 bg-opacity-75 z-50 flex justify-center items-center">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-3/4">
                        <h2 className="text-2xl font-bold mb-4">Preview Questions</h2>
                        <ul className="space-y-4">
                            {questions.map((q, index) => (
                                <li key={index} className="border-b border-gray-200 pb-4">
                                    <p className="font-semibold">{q.question}</p>
                                    <ul className="list-disc pl-5 mt-2">
                                        {q.options.map((option, idx) => (
                                            <li key={idx}>{option}</li>
                                        ))}
                                    </ul>
                                    <p className="mt-2 text-sm text-gray-600">
                                        Correct Answer: {q.correctOption}
                                    </p>
                                </li>
                            ))}
                        </ul>
                        <button
                            onClick={closePreview}
                            className="mt-4 bg-red-500 text-white py-2 px-4 rounded-lg"
                        >
                            Close Preview
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default QuizCreation;

import React, { useState } from "react";
import Sidebar from "../dashboard/sidebar";
import Header from "../dashboard/header";
import {
  Chart as ChartJS,
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Line, Bar, Pie } from "react-chartjs-2";
import { FaUsers, FaBookOpen, FaClock, FaChartLine} from "react-icons/fa";

// Register Chart.js components
ChartJS.register(LineElement, BarElement, CategoryScale, LinearScale, PointElement, ArcElement, Tooltip, Legend);

const Analytics = () => {
  const [activeChart, setActiveChart] = useState("line");

  const chartData = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: [
      {
        label: "Active Users",
        data: [50, 160, 170, 310, 420, 120, 280, 200, 220, 90, 265, 10],
        backgroundColor: ["#EFD316", "#FF9800", "#F44336", "#2196F3", "#FFC107"],
        borderColor: "#EFD316",
        borderWidth: 2,
        fill: true,
        tension: 0.3,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
    },
  };

  const userStats = [
    { id: 1, name: "John Doe", courses: 12, timeSpent: "20h 15m", score: "92%" },
    { id: 2, name: "Jane Smith", courses: 8, timeSpent: "15h 40m", score: "88%" },
    { id: 3, name: "Bob Johnson", courses: 5, timeSpent: "10h 30m", score: "80%" },
  ];

  return (
    <div className="min-h-screen flex flex-col bg-gray-100">
      {/* Header */}
      <div className="fixed top-0 left-0 right-0 z-20">
        <Header />
      </div>

      {/* Layout */}
      <div className="flex flex-1 pt-16 md:pt-20 overflow-hidden">
        {/* Sidebar */}
        <div className="hidden md:block w-60 bg-white shadow-lg z-10 flex-shrink-0">
          <Sidebar />
        </div>

        {/* Main Content */}
        <div className="flex-1 flex flex-col p-4 sm:p-6 space-y-6 overflow-auto">
          {/* Page Header */}
          <div className="bg-[#EFD316] text-white p-6 rounded-lg shadow-md">
            <h1 className="text-2xl font-bold text-black">Analytics Dashboard</h1>
            <p className="text-sm mt-1 text-black">
              Gain insights into platform performance and user activity.
            </p>
          </div>

          {/* Summary Cards */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
            <div className="bg-blue-100 p-4 rounded-lg text-center shadow-md">
              <FaUsers className="text-blue-500 text-4xl mx-auto mb-2" />
              <h3 className="text-lg font-bold text-blue-500">Total Users</h3>
              <p className="text-2xl font-semibold">1,230</p>
            </div>
            <div className="bg-[#EFD316] p-4 rounded-lg text-center shadow-md">
              <FaBookOpen className="text-yellow-500 text-4xl mx-auto mb-2" />
              <h3 className="text-lg font-bold text-black">Courses Available</h3>
              <p className="text-2xl font-semibold">45</p>
            </div>
            <div className="bg-yellow-100 p-4 rounded-lg text-center shadow-md">
              <FaClock className="text-yellow-500 text-4xl mx-auto mb-2" />
              <h3 className="text-lg font-bold text-yellow-500">Average Time Spent</h3>
              <p className="text-2xl font-semibold">2h 30m</p>
            </div>
            <div className="bg-red-100 p-4 rounded-lg text-center shadow-md">
              <FaChartLine className="text-red-500 text-4xl mx-auto mb-2" />
              <h3 className="text-lg font-bold text-red-500">Engagement Rate</h3>
              <p className="text-2xl font-semibold">85%</p>
            </div>
          </div>

          {/* Chart Section */}
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-bold mb-4">User Activity Over Time</h2>
            <div className="flex space-x-4 mb-4">
              <button
                onClick={() => setActiveChart("line")}
                className={`px-4 py-2 rounded-lg shadow ${
                  activeChart === "line" ? "bg-[#EFD316] text-white" : "bg-gray-100 text-gray-800"
                }`}
              >
                Line Chart
              </button>
              <button
                onClick={() => setActiveChart("bar")}
                className={`px-4 py-2 rounded-lg shadow ${
                  activeChart === "bar" ? "bg-[#EFD316] text-white" : "bg-gray-100 text-gray-800"
                }`}
              >
                Bar Chart
              </button>
              <button
                onClick={() => setActiveChart("pie")}
                className={`px-4 py-2 rounded-lg shadow ${
                  activeChart === "pie" ? "bg-[#EFD316] text-white" : "bg-gray-100 text-gray-800"
                }`}
              >
                Pie Chart
              </button>
            </div>
            <div className="h-64">
              {activeChart === "line" && <Line data={chartData} options={chartOptions} />}
              {activeChart === "bar" && <Bar data={chartData} options={chartOptions} />}
              {activeChart === "pie" && <Pie data={chartData} options={chartOptions} />}
            </div>
          </div>

          {/* Insights Table */}
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-bold mb-4">Detailed User Insights</h2>
            <div className="overflow-x-auto">
              <table className="min-w-full bg-gray-50 text-left rounded-lg">
                <thead>
                  <tr className="bg-gray-200">
                    <th className="px-4 py-2 font-bold">#</th>
                    <th className="px-4 py-2 font-bold">Name</th>
                    <th className="px-4 py-2 font-bold">Courses Completed</th>
                    <th className="px-4 py-2 font-bold">Time Spent</th>
                    <th className="px-4 py-2 font-bold">Score</th>
                  </tr>
                </thead>
                <tbody>
                  {userStats.map((user) => (
                    <tr key={user.id} className="hover:bg-gray-100">
                      <td className="px-4 py-2">{user.id}</td>
                      <td className="px-4 py-2">{user.name}</td>
                      <td className="px-4 py-2">{user.courses}</td>
                      <td className="px-4 py-2">{user.timeSpent}</td>
                      <td className="px-4 py-2">{user.score}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Analytics;

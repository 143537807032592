import React, { useState, useEffect } from "react";
import Sidebar from "../dashboard/sidebar";
import Header from "../dashboard/header";
import { FaStar, FaUser } from "react-icons/fa";

const SetReview = () => {
  const [reviews, setReviews] = useState([]);
  const [filter, setFilter] = useState("all");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const reviewsPerPage = 5;
  const [newReview, setNewReview] = useState({
    name: "",
    rating: 1,
    comment: "",
    type: "course", // "course" or "instruction"
  });

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setReviews([
        { id: 1, name: "John Doe", rating: 4, comment: "Great course!", type: "course", date: "2024-12-01" },
        { id: 2, name: "Jane Smith", rating: 5, comment: "Loved the instruction!", type: "instruction", date: "2024-11-28" },
      ]);
      setLoading(false);
    }, 1000);
  }, []);

  const handleSort = (criteria) => {
    const sortedReviews = [...reviews];
    if (criteria === "rating") {
      sortedReviews.sort((a, b) => b.rating - a.rating);
    } else if (criteria === "date") {
      sortedReviews.sort((a, b) => new Date(b.date) - new Date(a.date));
    }
    setReviews(sortedReviews);
  };

  const handleFilter = (rating) => {
    setFilter(rating);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewReview((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmitReview = (e) => {
    e.preventDefault();
    setReviews([...reviews, { ...newReview, id: reviews.length + 1, date: new Date().toISOString() }]);
    setNewReview({
      name: "",
      rating: 1,
      comment: "",
      type: "course",
    });
  };

  const currentReviews = reviews
    .filter((review) => (filter === "all" ? true : review.rating === parseInt(filter)))
    .slice((page - 1) * reviewsPerPage, page * reviewsPerPage);

  const totalPages = Math.ceil(reviews.length / reviewsPerPage);

  return (
    <div className="min-h-screen flex flex-col bg-gray-100">
      {/* Header */}
      <div className="fixed top-0 left-0 right-0 z-20">
        <Header />
      </div>

      {/* Layout */}
      <div className="flex flex-1 pt-16 md:pt-20 overflow-hidden">
        {/* Sidebar */}
        <div className="hidden md:block w-60 bg-white shadow-lg z-10 flex-shrink-0">
          <Sidebar />
        </div>

        {/* Main Content */}
        <div className="flex-1 flex flex-col p-4 sm:p-6 space-y-6 overflow-auto">
          {/* Page Header */}
          <div className="bg-[#EFD316] text-white p-6 rounded-lg shadow-md">
            <h1 className="text-2xl font-bold text-black">Trainee Reviews</h1>
            <p className="text-sm mt-1 text-black">Read reviews and feedback from your fellow trainees.</p>
          </div>

          {/* Review Form */}
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-bold mb-4">Submit Your Review</h2>
            <form onSubmit={handleSubmitReview} className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              <div className="mb-4">
                <label htmlFor="name" className="block text-gray-700">Your Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={newReview.name}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="rating" className="block text-gray-700">Rating</label>
                <select
                  id="rating"
                  name="rating"
                  value={newReview.rating}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                >
                  <option value="1">1 Star</option>
                  <option value="2">2 Stars</option>
                  <option value="3">3 Stars</option>
                  <option value="4">4 Stars</option>
                  <option value="5">5 Stars</option>
                </select>
              </div>
              <div className="mb-4">
                <label htmlFor="type" className="block text-gray-700">Review Type</label>
                <select
                  id="type"
                  name="type"
                  value={newReview.type}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                >
                  <option value="course">Course</option>
                  <option value="instruction">Instruction</option>
                </select>
              </div>
              <div className="mb-4">
                <label htmlFor="comment" className="block text-gray-700">Comment</label>
                <textarea
                  id="comment"
                  name="comment"
                  value={newReview.comment}
                  onChange={handleInputChange}
                  rows="4"
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                />
              </div>
              <button
                type="submit"
                className="w-full sm:w-auto px-4 py-2 rounded-lg bg-[#EFD316] text-black"
              >
                Submit Review
              </button>
            </form>
          </div>

          {/* Filters and Sorting */}
          <div className="flex justify-between mb-6">
            <div className="flex items-center space-x-4">
              <button
                onClick={() => handleSort("rating")}
                className="px-4 py-2 rounded-lg bg-[#EFD316] text-black"
              >
                Sort by Rating
              </button>
              <button
                onClick={() => handleSort("date")}
                className="px-4 py-2 rounded-lg bg-[#EFD316] text-black"
              >
                Sort by Date
              </button>
            </div>

            <div>
              <label htmlFor="ratingFilter" className="mr-2 text-gray-700">Filter by Rating:</label>
              <select
                id="ratingFilter"
                value={filter}
                onChange={(e) => handleFilter(e.target.value)}
                className="px-4 py-2 rounded-lg bg-gray-200"
              >
                <option value="all">All</option>
                <option value="5">5 Stars</option>
                <option value="4">4 Stars</option>
                <option value="3">3 Stars</option>
                <option value="2">2 Stars</option>
              </select>
            </div>
          </div>

          {/* Reviews Section */}
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-bold mb-4">Recent Reviews</h2>
            {loading ? (
              <div className="flex justify-center items-center">
                <div className="loader">Loading...</div>
              </div>
            ) : (
              <div className="space-y-6">
                {currentReviews.map((review) => (
                  <div key={review.id} className="border-b pb-4 mb-4">
                    <div className="flex items-center space-x-3">
                      <FaUser className="text-gray-500 text-xl" />
                      <div>
                        <h3 className="font-semibold text-gray-700">{review.name}</h3>
                        <p className="text-xs text-gray-500">{review.date}</p>
                      </div>
                    </div>
                    <div className="mt-2">
                      <div className="flex items-center space-x-1">
                        {Array.from({ length: review.rating }).map((_, index) => (
                          <FaStar key={index} className="text-yellow-400" />
                        ))}
                      </div>
                      <p className="mt-2 text-gray-600">{review.comment}</p>
                      <p className="mt-2 text-gray-500">Review Type: {review.type}</p>
                    </div>
                  </div>
                ))}
              </div>
            )}

            {/* Pagination */}
            {reviews.length > reviewsPerPage && (
              <div className="flex justify-center mt-6">
                <button
                  onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
                  className="px-4 py-2 rounded-lg bg-[#EFD316] text-white"
                >
                  Previous
                </button>
                <span className="px-4 py-2">{page} / {totalPages}</span>
                <button
                  onClick={() => setPage((prev) => Math.min(prev + 1, totalPages))}
                  className="px-4 py-2 rounded-lg bg-[#EFD316] text-white"
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetReview;

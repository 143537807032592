import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../dashboard/sidebar";
import Header from "../dashboard/header";
import { FaArrowDownWideShort } from "react-icons/fa6";
import Swal from "sweetalert2";
import axios from "axios";

const ExamList = () => {
    const [files, setFiles] = useState([]);
    const [timers, setTimers] = useState({});
    const navigate = useNavigate();


    const handleFileSelect = (fileName) => {
        // First, check the timer in the database
        fetch(`https://swiftsendify.com/learning_api/checkTimer.php?fileName=${fileName}`) // New API endpoint
            .then(response => response.json())
            .then(data => {
                if (data.status === 'success' && data.timer > 0) {
                    // Timer is valid, proceed with starting the test
                    const fileTimer = timers[fileName] || 0; // Use local timer if set, otherwise 0
                    navigate(`/questions/${fileName}`, { state: { fileTimer } });
                } else {
                    // Timer is 0 or an error occurred
                    Swal.fire({
                        icon: 'warning',
                        title: 'Cannot Start Test',
                        text: 'The timer for this test is not set. Please contact the administrator.',
                    });
                }
            })
            .catch(error => {
                console.error("Error checking timer:", error);
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'An error occurred while checking the timer.',
                });
            });
    };

    useEffect(() => {
        fetch("https://swiftsendify.com/learning_api/getFiles.php")
            .then((response) => response.json())
            .then((data) => {
                if (data.status === "success") {
                    setFiles(data.files);
                } else {
                    console.error("Failed to fetch files:", data.message);
                }
            })
            .catch((error) => console.error("Error:", error));
    }, []);

    /* const handleFileSelect = (fileName) => {
        const fileTimer = timers[fileName] || 0;
        navigate(`/questions/${fileName}`, { state: { fileTimer } });
    }; */

    const handleSetTimer = (fileName) => {
        Swal.fire({
            title: "Set Timer",
            input: "number",
            inputLabel: "Enter time in minutes",
            inputValue: 1, // Default time value
            inputAttributes: {
                min: 0,
                max: 120,
                step: 1,
            },
            showCancelButton: true,
            confirmButtonText: "Set Timer",
            cancelButtonText: "Cancel",
            preConfirm: (time) => {
                if (time && !isNaN(time)) {
                    const timeInSeconds = parseInt(time) * 60;
                    // Save the timer value to the state
                    setTimers((prevTimers) => ({
                        ...prevTimers,
                        [fileName]: timeInSeconds,
                    }));
                    // Send timer data to the server
                    saveTimerToDatabase(fileName, timeInSeconds);
                } else {
                    Swal.showValidationMessage("Please enter a valid number.");
                }
            },
        });
    };

    const saveTimerToDatabase = (fileName, timer) => {
        fetch("https://swiftsendify.com/learning_api/setTimer.php", {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            body: new URLSearchParams({
                file_name: fileName,
                timer: timer,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.status === "success") {
                    Swal.fire("Success", "Timer has been updated.", "success");
                } else {
                    Swal.fire("Error", data.message, "error");
                }
            })
            .catch((error) => {
                Swal.fire("Error", "Something went wrong.", "error");
            });
    };

    const formatTime = (timeInSeconds) => {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = timeInSeconds % 60;
        return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    };


    const [fullName, setFullName] = useState(""); // State for full name
    const [userRole, setUserRole] = useState(""); // State for user role
    const [error, setError] = useState(""); // State for error messages
    useEffect(() => {
        const storedFullName = localStorage.getItem("fullName"); // Retrieve full name from localStorage
        if (storedFullName) {
            setFullName(storedFullName);
            // Fetch user role based on full name
            axios
                .get(`https://swiftsendify.com/learning_api/fetchRole.php?fullName=${storedFullName}`) // Replace with your API endpoint
                .then((response) => {
                    if (response.data.status === "success") {
                        setUserRole(response.data.role); // Assuming `role` is returned in the API response
                    } else {
                        setError("Failed to fetch user role");
                    }
                })
                .catch((err) => {
                    console.error("Error fetching user role:", err);
                    setError("An error occurred while fetching user role.");
                });
        }
    }, []);
    const userName = fullName || "Guest"; // Display full name or default if not available
    const role = userRole || "Fetching role..."; // Display loading or default if not available


    return (
        <div className="min-h-screen flex flex-col bg-gray-100">
            <div className="fixed top-0 left-0 right-0 z-20">
                <Header />
            </div>
            <div className="flex flex-1 pt-16 md:pt-20">
                <div className="hidden md:block w-64 bg-white shadow-lg z-10">
                    <Sidebar />
                </div>
                <div className="flex-1 p-4 md:p-6 lg:p-8 pt-20 md:pt-24">
                    <div className="bg-white shadow-md rounded-lg p-6">
                        <h2 className="text-2xl font-bold mb-4 text-center">
                            Available Question Files
                        </h2>
                        {files.length === 0 ? (
                            <p className="text-gray-500 text-center">No files available.</p>
                        ) : (
                            <ul className="space-y-4">
                                {files.map((file, index) => (
                                    <li
                                        key={index}
                                        className="border border-gray-300 rounded-lg p-4 flex justify-between items-center"
                                    >
                                        <div>
                                            <span>{file.file_name}</span>
                                            <br />
                                            {timers[file.file_name] !== undefined && (
                                                <span>
                                                    Time left: {formatTime(timers[file.file_name])}
                                                </span>
                                            )}
                                        </div>
                                        <div className="flex items-center space-x-4">
                                            <button
                                                onClick={() => handleFileSelect(file.file_name)}
                                                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 flex items-center justify-center"
                                            >
                                                <FaArrowDownWideShort size={20} /> Start Test
                                            </button>
                                            <button
                                                onClick={() => handleSetTimer(file.file_name)}
                                                className={`bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 ${role === 'Student' ? 'invisible pointer-events-none' : ''
                                                    }`}
                                            >
                                                Set Timer
                                            </button>

                                        </div>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExamList;

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Landingpage from './views/landingpage';
import Dashboard from './views/dashboard';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CourseCreation from './views/courses/ManageCourse';
import ManageCourse from './views/courses/ManageCourse';
import ProgressTracking from './views/courses/ProgressTracking';
import QuizCreation from './views/quiz/QuizCreation';
import AutomaticGrading from './views/quiz/AutomaticGrading';
import OverallView from './views/quiz/OverallView';
import DiscussionModule from './views/interaction/DiscussionModule';
import NotificationModule from './views/interaction/NotificationModule';
import CourseCompletion from './views/tracking/CourseCompletion';
import Analytics from './views/tracking/Analytics';
import Review from './views/certification/Reviews';
import SetReview from './views/certification/SetReviews';
import UploadQuestions from './views/cbt/UploadQuestions';
import ExamList from './views/cbt/ExamList';
import QuestionsPage from './views/cbt/QuestionsPage';
import DownloadResult from './views/cbt/DownloadResult';





function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Landingpage />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/course-creation" element={<CourseCreation/>} />
          <Route path="/manage-course" element={<ManageCourse/>} />
          <Route path="/Progress-tracking" element={<ProgressTracking/>} />
          <Route path="/quiz-creation" element={<QuizCreation/>} />
          <Route path="/grading" element={<AutomaticGrading/>} />
          <Route path="/overall-view" element={<OverallView/>} />
          <Route path="/interaction-blog" element={<DiscussionModule/>} />
          <Route path="/notification" element={<NotificationModule/>} />
          <Route path="/course-completion" element={<CourseCompletion/>} />
          <Route path="/analytics" element={<Analytics/>} />
          <Route path="/reviews" element={<Review/>} />
          <Route path="/set-reviews" element={<SetReview/>} />
          <Route path="/upload-question" element={<UploadQuestions/>} />
          <Route path="/exam-question" element={<ExamList/>} />
          <Route path="/questions/:fileName" element={<QuestionsPage/>} />
          <Route path="/download-result" element={<DownloadResult/>} />

        </Routes>
      </div>
    </Router>
  );
}

export default App;



import React, { useEffect, useState } from "react";
import { MdArrowUpward } from "react-icons/md";
import Sidebar from "../dashboard/sidebar";
import Header from "../dashboard/header";
import Swal from "sweetalert2";

const DownloadResult = () => {
    const [results, setResults] = useState([]);
    const [questionsCount, setQuestionsCount] = useState({}); // Question count per course
    const [fileNames, setFileNames] = useState([]); // Available file names
    const [selectedFileName, setSelectedFileName] = useState(""); // Currently selected file name
    const [showScrollToTop, setShowScrollToTop] = useState(false);

    // Fetch results, question counts, and file names
    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch results
                const resultResponse = await fetch("https://swiftsendify.com/learning_api/getResults.php");
                const resultData = await resultResponse.json();
                console.log("Results Data:", resultData); // Debugging log

                if (resultData?.status === "success" && Array.isArray(resultData.results)) {
                    const uniqueResults = resultData.results.filter(
                        (result, index, self) =>
                            index === self.findIndex(
                                (r) => r.user_name === result.user_name && r.course === result.course && r.score === result.score
                            )
                    );
                    setResults(uniqueResults);
                } else {
                    setResults([]);
                    console.log("No results fetched.");
                }

                // Fetch question counts
                const questionsResponse = await fetch("https://swiftsendify.com/learning_api/getQuestionsCount.php");
                const questionsData = await questionsResponse.json();
                console.log("Questions Data:", questionsData); // Debugging log

                if (questionsData?.status === "success" && Array.isArray(questionsData.questions)) {
                    const countMap = {};
                    questionsData.questions.forEach((question) => {
                        countMap[question.file_name] = question.question_count;
                    });
                    setQuestionsCount(countMap);
                } else {
                    setQuestionsCount({});
                }

                // Fetch file names
                const fileNameResponse = await fetch("https://swiftsendify.com/learning_api/getFileNames.php");
                const fileNameData = await fileNameResponse.json();
                console.log("File Names Data:", fileNameData); // Debugging log

                if (fileNameData?.status === "success" && Array.isArray(fileNameData.file_names)) {
                    // If file_names contains objects with file_name, map to extract it
                    setFileNames(fileNameData.file_names.map(file => file.file_name || file));
                } else {
                    setFileNames([]);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                setResults([]);
                setQuestionsCount({});
                setFileNames([]);
            }
        };

        fetchData();
    }, []);

    // Filter results by selected file name
    const filteredResults = selectedFileName
        ? results.filter((result) => result.course === selectedFileName)
        : results;

    // Handle file name selection
    const handleFileNameChange = (e) => {
        setSelectedFileName(e.target.value);
    };

    // Handle CSV download
    const handleDownload = () => {
        if (!filteredResults || filteredResults.length === 0) {
            Swal.fire("Error", "No results available to download.", "error");
            return;
        }

        const headers = ["ID", "User Name", "Course", "Score", "Created At", "OUT OF SCORE"];
        const rows = filteredResults.map((result, index) => {
            const outOfScore = questionsCount[result.course] || "N/A"; // Use N/A if course count not found
            return [
                index + 1,
                `"${result.user_name.replace(/"/g, '""')}"`,
                `"${result.course.replace(/"/g, '""')}"`,
                `"${result.score}"`,
                `"${result.created_at.replace(/"/g, '""')}"`,
                `"${outOfScore}"`,
            ];
        });

        const csvContent = [headers.join(","), ...rows.map((row) => row.join(","))].join("\n");

        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);

        link.href = url;
        link.setAttribute("download", "results.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    // Scroll to top functionality
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const handleScroll = () => {
        if (window.scrollY > 300) {
            setShowScrollToTop(true);
        } else {
            setShowScrollToTop(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div className="min-h-screen flex flex-col bg-gray-100 relative">
            <div className="fixed top-0 left-0 right-0 z-20">
                <Header />
            </div>
            <div className="flex flex-1 pt-16 md:pt-20">
                <div className="hidden md:block w-64 bg-white shadow-lg z-10">
                    <Sidebar />
                </div>
                <div className="flex-1 p-4 md:p-6 lg:p-8 pt-20 md:pt-24">
                    <div className="bg-white shadow-md rounded-lg p-6">
                        <h2 className="text-2xl font-bold mb-4 text-center">Download Results</h2>
                        <div className="mb-4">
                            <label htmlFor="fileName" className="block text-sm font-medium text-gray-700">
                                Filter by File Name:
                            </label>
                            <select
                                id="fileName"
                                value={selectedFileName}
                                onChange={handleFileNameChange}
                                className="block w-full mt-2 border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            >
                                <option value="">All File Names</option>
                                {fileNames.map((fileName, index) => (
                                    <option key={index} value={fileName}>
                                        {fileName}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {filteredResults && filteredResults.length === 0 ? (
                            <p className="text-gray-500 text-center">No results available.</p>
                        ) : (
                            <div>
                                <table className="table-auto w-full border-collapse border border-gray-300">
                                    <thead>
                                        <tr className="bg-gray-100">
                                            <th className="border px-4 py-2">ID</th>
                                            <th className="border px-4 py-2">User Name</th>
                                            <th className="border px-4 py-2">Course</th>
                                            <th className="border px-4 py-2">Score</th>
                                            <th className="border px-4 py-2">Created At</th>
                                            <th className="border px-4 py-2">OUT OF SCORE</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredResults.map((result, index) => {
                                            const outOfScore = questionsCount[result.course] || "N/A"; // Use fallback N/A if no count
                                            return (
                                                <tr key={index}>
                                                    <td className="border px-4 py-2">{index + 1}</td>
                                                    <td className="border px-4 py-2">{result.user_name}</td>
                                                    <td className="border px-4 py-2">{result.course}</td>
                                                    <td className="border px-4 py-2">{result.score}</td>
                                                    <td className="border px-4 py-2">{result.created_at}</td>
                                                    <td className="border px-4 py-2">{outOfScore}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                                <button
                                    onClick={handleDownload}
                                    className="bg-blue-500 text-white px-4 py-2 rounded mt-4 hover:bg-blue-600"
                                >
                                    Download Results as CSV
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DownloadResult;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
/* import MyLogo from './images/ABi.png'; */

const SignUp = ({ closeModal }) => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { fullName, email, password, confirmPassword } = formData;

    if (!fullName || !email || !password || !confirmPassword) {
      toast.error("All fields are required!", { position: "top-center" });
      return;
    }
    if (password !== confirmPassword) {
      toast.error("Passwords do not match!", { position: "top-center" });
      return;
    }

    try {
      const response = await fetch("https://swiftsendify.com/learning_api/save_user.php", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ fullName, email, password }),
      });

      const data = await response.json();

      if (data.status === "error") {
        if (data.message === "Email already exists.") {
          toast.error("This ID is already registered. Please try a different ID.", { position: "top-center" });
        } else if (data.message === "Full name already exists.") {
          toast.error("This full name is already registered. Please use a different name.", { position: "top-center" });
        } else {
          toast.error(data.message, { position: "top-center" });
        }
      } else if (data.status === "success") {
        toast.success(data.message, { position: "top-center" });
        setFormData({ fullName: "", email: "", password: "", confirmPassword: "" });
      }
    } catch (err) {
      toast.error("An error occurred. Please try again.", { position: "top-center" });
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <ToastContainer />
      <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-sm relative">
        <button onClick={closeModal} className="absolute top-3 right-3 text-gray-500 hover:text-gray-700">
          &times;
        </button>

        <div className="text-center">
          {/* <img src={MyLogo} alt="Logo" className="w-30 h-12 mx-auto mb-4" /> */}
          <h2 className="text-2xl font-bold mb-2">Create your account</h2>
          <p className="text-sm text-gray-500 mb-4">
            Already have an account?{" "}
            <Link className="text-[#FDD835] hover:underline">
              Log in
            </Link>
          </p>

          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <input
                type="text"
                name="fullName"
                placeholder="Full Name"
                className="w-full px-4 py-2 border rounded focus:outline-none focus:ring focus:ring-yellow-300"
                onChange={handleInputChange}
                value={formData.fullName}
              />
            </div>
            <div className="mb-3">
              <input
                type="text"
                name="email"
                placeholder="Matric No"
                className="w-full px-4 py-2 border rounded focus:outline-none focus:ring focus:ring-yellow-300"
                onChange={handleInputChange}
                value={formData.email}
              />
            </div>
            <div className="mb-3 relative">
              <input
                type={passwordVisible ? "text" : "password"}
                name="password"
                placeholder="Password"
                className="w-full px-4 py-2 border rounded focus:outline-none focus:ring focus:ring-yellow-300"
                onChange={handleInputChange}
                value={formData.password}
              />
              <button
                type="button"
                onClick={() => setPasswordVisible(!passwordVisible)}
                className="absolute right-3 top-2/4 transform -translate-y-2/4 text-gray-500"
              >
                {passwordVisible ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
            <div className="mb-3 relative">
              <input
                type={confirmPasswordVisible ? "text" : "password"}
                name="confirmPassword"
                placeholder="Confirm Password"
                className="w-full px-4 py-2 border rounded focus:outline-none focus:ring focus:ring-yellow-300"
                onChange={handleInputChange}
                value={formData.confirmPassword}
              />
              <button
                type="button"
                onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
                className="absolute right-3 top-2/4 transform -translate-y-2/4 text-gray-500"
              >
                {confirmPasswordVisible ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
            <div className="flex items-center mb-4">
              <input type="checkbox" className="form-checkbox text-[#FDD835]" />
              <span className="ml-2 text-sm">
                I agree to the{" "}
                <Link to="/terms" className="text-[#FDD835] hover:underline">
                  Terms and Conditions
                </Link>
              </span>
            </div>
            <button type="submit" className="w-full bg-[#FDD835] text-black py-2 rounded hover:bg-yellow-600 transition">
              Sign Up
            </button>
          </form>
          <div className="my-4 flex items-center justify-center">
            <hr className="w-full border-gray-300" />
            <span className="mx-3 text-gray-500">OR</span>
            <hr className="w-full border-gray-300" />
          </div>
          <Link to="/sso-signup" className="w-full text-[#000000] hover:underline text-center block mb-2">
            Sign up with SSO
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SignUp;

import React, { useState } from "react";
import { Pie } from "react-chartjs-2";
import Sidebar from "../dashboard/sidebar";
import Header from "../dashboard/header";

const AutomaticGrading = () => {
  const user = {
    name: "John Doe",
    id: "12345",
    quizTitle: "JavaScript Basics Quiz",
    totalScore: 80,
    maxScore: 100,
    grade: "A",
  };

  const [questions, setQuestions] = useState([
    { question: "What is the output of `typeof null`?", userAnswer: "object", correctAnswer: "object", marks: 10 },
    { question: "Which company developed JavaScript?", userAnswer: "Microsoft", correctAnswer: "Netscape", marks: 0 },
    { question: "What does `NaN` stand for?", userAnswer: "Not a Number", correctAnswer: "Not a Number", marks: 10 },
  ]);

  const totalQuestions = questions.length;
  const passed = questions.filter((q) => q.marks > 0).length;
  const failed = totalQuestions - passed;

  const chartData = {
    labels: ["Passed", "Failed"],
    datasets: [
      {
        label: "Quiz Results",
        data: [passed, failed],
        backgroundColor: ["#4caf50", "#f44336"],
        borderColor: ["#4caf50", "#f44336"],
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
  };

  const sortTable = (key) => {
    const sorted = [...questions].sort((a, b) => (a[key] > b[key] ? 1 : -1));
    setQuestions(sorted);
  };

  return (
    <div className="min-h-screen flex flex-col bg-gray-100">
      {/* Header */}
      <div className="fixed top-0 left-0 right-0 z-20">
        <Header />
      </div>

      {/* Layout */}
      <div className="flex flex-1 pt-16 md:pt-20 overflow-hidden">
        {/* Sidebar */}
        <div className="hidden md:block w-60 bg-white shadow-lg z-10 flex-shrink-0">
          <Sidebar />
        </div>

        {/* Main Content */}
        <div className="flex-1 flex flex-col p-4 sm:p-6 space-y-6 overflow-auto">
          <div className="flex flex-col md:flex-row md:space-x-6 space-y-6 md:space-y-0">
            {/* User Info and Grade Summary */}
            <div className="flex flex-col flex-1 bg-white rounded-lg shadow-lg p-6">
              <h2 className="text-xl md:text-2xl font-bold text-gray-800 mb-4">Automatic Grading</h2>
              <p className="text-gray-600"><strong>Name:</strong> {user.name}</p>
              <p className="text-gray-600"><strong>ID:</strong> {user.id}</p>
              <p className="text-gray-600"><strong>Quiz Title:</strong> {user.quizTitle}</p>
              <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mt-6">
                <div className="bg-blue-100 text-blue-800 p-4 rounded-lg shadow text-center">
                  <p className="text-lg font-bold">Total Score</p>
                  <p className="text-2xl">{user.totalScore}/{user.maxScore}</p>
                </div>
                <div className="bg-green-100 text-green-800 p-4 rounded-lg shadow text-center">
                  <p className="text-lg font-bold">Percentage</p>
                  <p className="text-2xl">{((user.totalScore / user.maxScore) * 100).toFixed(2)}%</p>
                </div>
                <div className="bg-yellow-100 text-yellow-800 p-4 rounded-lg shadow text-center">
                  <p className="text-lg font-bold">Grade</p>
                  <p className="text-2xl">{user.grade}</p>
                </div>
              </div>
            </div>

            {/* Chart Section */}
            <div className="flex-1 bg-white rounded-lg shadow-lg p-6">
              <h3 className="text-lg font-bold text-gray-800 mb-4">Results Chart</h3>
              <div className="h-64">
                <Pie data={chartData} options={chartOptions} />
              </div>
            </div>
          </div>

          {/* Questions Breakdown */}
          <div className="bg-white rounded-lg shadow-lg p-6">
            <h3 className="text-lg font-bold text-gray-800 mb-4">Question Breakdown</h3>
            <div className="overflow-x-auto">
              {/* Table for larger screens */}
              <table className="hidden md:table w-full bg-white border rounded-lg">
                <thead>
                  <tr className="bg-[#EFD316] text-gray-800 uppercase text-sm leading-normal">
                    <th
                      className="py-3 px-6 text-left cursor-pointer hover:bg-[#e5c30f]"
                      onClick={() => sortTable("question")}
                    >
                      Question
                    </th>
                    <th
                      className="py-3 px-6 text-left cursor-pointer hover:bg-[#e5c30f]"
                      onClick={() => sortTable("userAnswer")}
                    >
                      Your Answer
                    </th>
                    <th
                      className="py-3 px-6 text-left cursor-pointer hover:bg-[#e5c30f]"
                      onClick={() => sortTable("correctAnswer")}
                    >
                      Correct Answer
                    </th>
                    <th
                      className="py-3 px-6 text-center cursor-pointer hover:bg-[#e5c30f]"
                      onClick={() => sortTable("marks")}
                    >
                      Marks
                    </th>
                  </tr>
                </thead>
                <tbody className="text-gray-600 text-sm font-light">
                  {questions.map((q, index) => (
                    <tr
                      key={index}
                      className={`border-b ${q.marks === 0 ? "bg-[#EFD316]/50" : "bg-white"}`}
                    >
                      <td className="py-3 px-6 text-left">{q.question}</td>
                      <td className="py-3 px-6 text-left">{q.userAnswer}</td>
                      <td className="py-3 px-6 text-left">{q.correctAnswer}</td>
                      <td className="py-3 px-6 text-center">{q.marks}</td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {/* Cards for mobile screens */}
              <div className="md:hidden space-y-4">
                {questions.map((q, index) => (
                  <div
                    key={index}
                    className={`p-4 rounded-lg shadow ${q.marks === 0 ? "bg-[#EFD316]/50" : "bg-white"}`}
                  >
                    <p className="text-gray-800"><strong>Question:</strong> {q.question}</p>
                    <p className="text-gray-800"><strong>Your Answer:</strong> {q.userAnswer}</p>
                    <p className="text-gray-800"><strong>Correct Answer:</strong> {q.correctAnswer}</p>
                    <p className="text-gray-800"><strong>Marks:</strong> {q.marks}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AutomaticGrading;

import React from "react";
import Sidebar from "../dashboard/sidebar";
import Header from "../dashboard/header";

const CourseCompletion = () => {
  return (
    <div className="min-h-screen flex flex-col bg-gray-100">
      {/* Header */}
      <div className="fixed top-0 left-0 right-0 z-20">
        <Header />
      </div>

      {/* Layout */}
      <div className="flex flex-1 pt-16 md:pt-20 overflow-hidden">
        {/* Sidebar */}
        <div className="hidden md:block w-60 bg-white shadow-lg z-10 flex-shrink-0">
          <Sidebar />
        </div>

        {/* Main Content */}
        <div className="flex-1 flex flex-col p-4 sm:p-6 space-y-6 overflow-auto">
          {/* Page Header */}
          <div className="bg-[#EFD316] text-white p-6 rounded-lg shadow-md">
            <h1 className="text-2xl font-bold text-black">Course Completion</h1>
            <p className="text-sm mt-1 text-black">
              Congratulations! You have successfully completed the course. Review your achievements and download your certificate.
            </p>
          </div>

          {/* Completion Summary */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
            <div className="bg-blue-100 p-4 rounded-lg text-center">
              <h3 className="text-lg font-bold text-blue-500">Courses Completed</h3>
              <p className="text-2xl font-semibold">5</p>
            </div>
            <div className="bg-green-100 p-4 rounded-lg text-center">
              <h3 className="text-lg font-bold text-green-500">Average Score</h3>
              <p className="text-2xl font-semibold">92%</p>
            </div>
            <div className="bg-yellow-100 p-4 rounded-lg text-center">
              <h3 className="text-lg font-bold text-yellow-500">Time Spent</h3>
              <p className="text-2xl font-semibold">18 hours</p>
            </div>
            <div className="bg-red-100 p-4 rounded-lg text-center">
              <h3 className="text-lg font-bold text-red-500">Certificates Earned</h3>
              <p className="text-2xl font-semibold">2</p>
            </div>
          </div>

          {/* Certificate Section */}
          <div className="bg-white p-6 rounded-lg shadow-md text-center">
            <h2 className="text-xl font-bold mb-4">Your Certificate</h2>
            <div className="flex justify-center mb-6">
              {/* Certificate Template */}
              <div className="bg-white shadow-lg rounded-lg border border-gray-300 w-full max-w-4xl p-8">
                <div className="border-4 border-yellow-500 rounded-md p-6">
                  <div className="text-center">
                    <h1 className="text-4xl font-bold text-gray-700 uppercase mb-4">
                      Certificate of Completion
                    </h1>
                    <p className="text-lg text-gray-600 mb-6">This is to certify that</p>
                    <h2 className="text-3xl font-semibold text-gray-800 uppercase underline mb-4">
                      [Recipient Name]
                    </h2>
                    <p className="text-lg text-gray-600">has successfully completed the course</p>
                    <h3 className="text-2xl font-bold text-gray-800 mt-2">
                      [Course Title]
                    </h3>
                    <p className="text-lg text-gray-600 mt-4">on [Date of Completion]</p>
                    <div className="mt-8 flex justify-between items-center">
                      <div className="text-center">
                        <div className="h-16 border-b-2 border-gray-400 w-40 mx-auto mb-2"></div>
                        <p className="text-sm text-gray-600">Instructor Signature</p>
                      </div>
                      <div className="w-24 h-24 rounded-full bg-yellow-500 flex items-center justify-center shadow-md">
                        <span className="text-white font-bold text-xl">Seal</span>
                      </div>
                      <div className="text-center">
                        <div className="h-16 border-b-2 border-gray-400 w-40 mx-auto mb-2"></div>
                        <p className="text-sm text-gray-600">Director Signature</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button className="bg-[#EFD316] text-black py-3 px-6 rounded-lg hover:bg-white hover:text-[#EFD316]  shadow-md">
              Download Certificate
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseCompletion;

import React from "react";
import Sidebar from "../dashboard/sidebar";
import Header from "../dashboard/header";
import { Bar, Pie } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, ArcElement, Tooltip, Legend } from "chart.js";

// Register chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Tooltip, Legend);

const ProjectTracking = () => {
    const enrolledCourses = [
        {
            title: "Introduction to Control Systems",
            progress: 20,
            quizzes: "2/10",
            lessons: "3/5",
            status: "In Progress",
        },
        {
            title: "Basics of Industrial Automation",
            progress: 0,
            quizzes: "0/10",
            lessons: "0/10",
            status: "Not Started",
        },
        {
            title: "Advanced Robotics and Control",
            progress: 100,
            quizzes: "10/10",
            lessons: "5/5",
            status: "Completed",
        },
        {
            title: "Mechatronics Design Principles",
            progress: 100,
            quizzes: "10/10",
            lessons: "5/5",
            status: "Completed",
        },
    ];

    // Bar chart data for courses progress
    const barData = {
        labels: enrolledCourses.map((course) => course.title),
        datasets: [
            {
                label: "Progress (%)",
                data: enrolledCourses.map((course) => course.progress),
                backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0"],
            },
        ],
    };

    // Pie chart data for courses status breakdown
    const pieData = {
        labels: ["Completed", "In Progress", "Not Started"],
        datasets: [
            {
                data: [
                    enrolledCourses.filter((course) => course.status === "Completed").length,
                    enrolledCourses.filter((course) => course.status === "In Progress").length,
                    enrolledCourses.filter((course) => course.status === "Not Started").length,
                ],
                backgroundColor: ["#36A2EB", "#FFCE56", "#FF6384"],
            },
        ],
    };

    return (
        <div className="min-h-screen flex flex-col bg-gray-100">
            {/* Header */}
            <div className="fixed top-0 left-0 right-0 z-20">
                <Header />
            </div>

            {/* Layout */}
            <div className="flex flex-1 pt-16 md:pt-20">
                {/* Sidebar */}
                <div className="hidden md:block w-64 bg-white shadow-lg z-10">
                    <Sidebar />
                </div>

                {/* Main Content */}
                <div className="flex-1 p-4 md:p-6 lg:p-8 pt-20 md:pt-24">
                    <div className="p-6 space-y-8">
                        {/* Progress Section */}
                        <div className="bg-white shadow-md rounded-lg p-6">
                            <div className="flex justify-between items-center mb-4">
                                <h2 className="text-2xl font-bold">Progress</h2>
                                <span className="text-sm text-gray-500">Beginner</span>
                            </div>
                            <div className="relative bg-gray-200 h-6 rounded-full">
                                <div
                                    className="absolute top-0 left-0 h-6 bg-[#EFD316] rounded-full"
                                    style={{ width: "40%" }}
                                ></div>
                            </div>
                        </div>

                        {/* Charts Section */}
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            {/* Bar Chart */}
                            <div className="bg-white shadow-md rounded-lg p-6 flex flex-col items-center justify-center h-[35vh] md:h-[60vh]">
                                <h2 className="text-2xl font-bold mb-4">Course Progress</h2>
                                <div className="w-full h-full">
                                    <Bar
                                        data={barData}
                                        options={{
                                            responsive: true,
                                            maintainAspectRatio: false, // Allow resizing based on container
                                            plugins: {
                                                legend: {
                                                    display: true,
                                                    position: "top", // Example of customizing the legend position
                                                },
                                            },
                                        }}
                                    />
                                </div>
                            </div>

                            {/* Pie Chart */}
                            <div className="bg-white shadow-md rounded-lg p-6 flex flex-col items-center justify-center h-[35vh] md:h-[60vh]">
                                <h2 className="text-2xl font-bold mb-4">Course Status Breakdown</h2>
                                <div className="w-full h-full">
                                    <Pie
                                        data={pieData}
                                        options={{
                                            responsive: true,
                                            maintainAspectRatio: false, // Allow resizing based on container
                                            plugins: {
                                                legend: {
                                                    display: true,
                                                    position: "top", // Example of customizing the legend position
                                                },
                                            },
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectTracking;

import React, { useState } from "react";
import Sidebar from "../dashboard/sidebar";
import Header from "../dashboard/header";
import passport from '../../views/images/PASSPORT.jpg';  // Correct image import

const DiscussionModule = () => {
  const [messages, setMessages] = useState([
    { sender: "Instructor", content: "Hello, how can I help you today?", img: passport },
    { sender: "John Doe", content: "I have some questions about the last quiz.", img: passport },
    { sender: "Instructor", content: "Sure, feel free to ask!", img: passport },
  ]);

  const [newMessage, setNewMessage] = useState("");

  const handleSendMessage = () => {
    if (newMessage.trim()) {
      setMessages([...messages, { sender: "Trainee", content: newMessage, img: "/path/to/trainee-image.jpg" }]);
      setNewMessage("");
    }
  };

  return (
    <div className="min-h-screen flex flex-col bg-gray-100">
      {/* Header */}
      <div className="fixed top-0 left-0 right-0 z-20">
        <Header />
      </div>

      {/* Layout */}
      <div className="flex flex-1 pt-16 md:pt-20 overflow-hidden">
        {/* Sidebar */}
        <div className="hidden md:block w-60 bg-white shadow-lg z-10 flex-shrink-0">
          <Sidebar />
        </div>

        {/* Main Content */}
        <div className="flex-1 flex flex-col p-4 sm:p-6 space-y-6 overflow-auto">
          <div className="bg-white rounded-lg shadow-lg p-6 space-y-4">
            <h2 className="text-xl md:text-2xl font-bold text-gray-800 mb-4">Discussion Module</h2>
            <div className="h-96 overflow-y-auto p-4 bg-gray-50 rounded-lg shadow-inner space-y-4">
              {/* Display Messages */}
              {messages.map((msg, index) => (
                <div
                  key={index}
                  className={`flex ${msg.sender === "Instructor" ? "justify-start" : "justify-end"}`}
                >
                  <div
                    className={`flex items-center space-x-4 ${msg.sender === "Instructor" ? "flex-row-reverse" : ""}`}
                  >
                    {/* User Image */}
                    <img
                      src={msg.img}
                      alt={`${msg.sender}'s avatar`}
                      className="w-10 h-10 rounded-full object-cover border-2 border-gray-300"
                    />
                    {/* Message Bubble */}
                    <div
                      className={`max-w-xs p-3 rounded-lg shadow-lg ${msg.sender === "Instructor" ? "bg-blue-100 text-blue-800" : "bg-green-100 text-green-800"}`}
                    >
                      <p className="font-semibold">{msg.sender}</p>
                      <p>{msg.content}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Input Section */}
            <div className="flex items-center space-x-4 mt-4">
              <input
                type="text"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder="Type your message..."
                className="flex-1 p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
              />
              <button
                onClick={handleSendMessage}
                className="bg-blue-500 text-white p-3 rounded-lg shadow hover:bg-blue-600"
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiscussionModule;

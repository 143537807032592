import React, { useState, useEffect } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "../dashboard/sidebar";
import Header from "../dashboard/header";

const ManageCourse = () => {
  const [courses, setCourses] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [courseDetails, setCourseDetails] = useState({
    course_name: "",
    description: "",
    image_path: "",
  });
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);  // To control modal visibility
  const [courseToDelete, setCourseToDelete] = useState(null);  // Store the course ID to be deleted


  // Fetch courses from the PHP API
  const fetchCourses = async () => {
    setLoading(true); // Optional: Show loading while fetching
    try {
      const response = await fetch("https://swiftsendify.com/learning_api/get_courses.php");
      const data = await response.json();

      if (data.status === "success") {
        setCourses(data.courses);
      } else {
        setError(data.message || "Failed to fetch courses");
      }
    } catch (err) {
      setError("An error occurred while fetching courses");
      console.error(err);
    } finally {
      setLoading(false); // Hide loading
    }
  };

  // Call fetchCourses in useEffect to load courses on page load
  useEffect(() => {
    fetchCourses();
  }, []);



  // Handle edit button click
  const handleEdit = (courseId) => {
    const course = courses.find((c) => c.id === courseId);
    setCourseDetails({
      course_name: course.course_name,
      description: course.description,
      image_path: course.image_path,
    });
    setSelectedCourse(course);
    setIsModalOpen(true);
  };

  // Handle modal close
  const closeModal = () => {
    setIsModalOpen(false);
  };



  // Delete course from the database
  // Handle delete button click
  const handleDelete = (courseId) => {
    setCourseToDelete(courseId); // Set the course to delete
    setIsDeleteModalOpen(true);  // Open the modal
  };

  // Delete course function
  const deleteCourse = async (courseId) => {
    try {
      const response = await fetch("https://swiftsendify.com/learning_api/delete_course.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: courseId }),
      });

      const data = await response.json();

      if (data.status === "success") {
        toast.success("Course deleted successfully!");
        fetchCourses(); // Refresh courses list after deletion
      } else {
        toast.error("Failed to delete course");
      }
    } catch (err) {
      console.error("Error during course deletion:", err);
      toast.error("An error occurred while deleting the course");
    }

    setIsDeleteModalOpen(false);  // Close the modal after deletion
  };






  // Handle save changes
  const handleSaveChanges = async () => {
    try {
      const formData = new FormData();
      formData.append("id", selectedCourse.id);
      formData.append("course_name", courseDetails.course_name);
      formData.append("description", courseDetails.description);

      if (courseDetails.image) {
        formData.append("image", courseDetails.image);
      }

      const response = await fetch("https://swiftsendify.com/learning_api/update_course.php", {
        method: "POST",
        body: formData,
      });

      const data = await response.json();
      if (data.status === "success") {
        toast.success("Course updated successfully!");
        closeModal();
        fetchCourses(); // Refresh the courses list
      } else {
        toast.error(data.message || "Failed to update the course");
      }
    } catch (err) {
      console.error("Error during course update:", err);
      toast.error("An error occurred while updating the course");
    }
  };




  return (
    <div className="min-h-screen flex flex-col bg-gray-100">
      {/* Toast Notifications */}
      <ToastContainer />

      {/* Header */}
      <div className="fixed top-0 left-0 right-0 z-20">
        <Header />
      </div>

      {/* Layout */}
      <div className="flex flex-1 pt-16 md:pt-20">
        {/* Sidebar */}
        <div className="hidden md:block w-64 bg-white shadow-lg z-10">
          <Sidebar />
        </div>

        {/* Main Content */}
        <div className="flex-1 p-4 md:p-6 lg:p-8 pt-20 md:pt-24">
          <div className="p-6 space-y-8">
            <div className="flex items-center justify-center mb-6">
              <div>
                <h1 className="text-3xl font-bold text-gray-800">Manage Modules</h1>
                <p className="text-sm text-center text-gray-600">
                  View, edit, and manage Modules effectively.
                </p>
              </div>
            </div>

            <div className="bg-white shadow-lg rounded-lg p-6">
              <h2 className="text-2xl font-bold mb-4">Modules List</h2>
              {loading ? (
                <p className="text-gray-600 animate-pulse">Loading Modules...</p>
              ) : error ? (
                <p className="text-red-500">{error}</p>
              ) : (
                <div className="overflow-x-auto">
                  <table className="table-auto w-full text-left border-collapse border border-gray-300">
                    <thead className="bg-gradient-to-r from-gray-100 to-gray-200">
                      <tr>
                        <th className="border border-gray-300 px-4 py-2">Thumbnail</th>
                        <th className="border border-gray-300 px-4 py-2">Module Title</th>
                        <th className="border border-gray-300 px-4 py-2">Description</th>
                        <th className="border border-gray-300 px-4 py-2">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {courses.map((course) => (
                        <tr
                          key={course.id}
                          className="bg-gray-50 border-b border-gray-300 hover:bg-blue-50 transition-colors duration-300"
                        >
                          <td className="border border-gray-300 px-4 py-2">
                            <img
                              src={`https://swiftsendify.com/learning_api/${course.image_path}`}
                              alt={course.course_name}
                              className="h-16 w-16 object-cover rounded-md shadow-md hover:shadow-lg transition-shadow duration-300"
                            />
                          </td>
                          <td className="border border-gray-300 px-4 py-2 font-medium text-gray-800">
                            {course.course_name}
                          </td>
                          <td className="border border-gray-300 px-4 py-2 text-gray-600">
                            {course.description}
                          </td>
                          <td className="border border-gray-300 px-4 py-2">
                            <div className="flex space-x-2">
                              <button
                                className="bg-[#EFD316] text-black px-4 py-2 rounded-lg hover:bg-blue-600 text-sm shadow transform transition-transform duration-300 hover:scale-105"
                                onClick={() => handleEdit(course.id)}
                              >
                                <FaEdit className="inline mr-2" /> Edit
                              </button>
                              <button
                                className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 text-sm shadow transform transition-transform duration-300 hover:scale-105"
                                onClick={() => handleDelete(course.id)}  // Trigger handleDelete
                              >
                                <FaTrash className="inline mr-2" /> Delete
                              </button>

                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Modal for editing course */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50">
          <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full">
            <h2 className="text-2xl font-bold mb-4">Edit Module</h2>
            <div className="space-y-4">
              <div>
                <label htmlFor="course_name" className="block text-sm text-left font-medium text-gray-700">
                  Module Name
                </label>
                <input
                  type="text"
                  id="course_name"
                  value={courseDetails.course_name}
                  onChange={(e) =>
                    setCourseDetails({ ...courseDetails, course_name: e.target.value })
                  }
                  className="w-full px-4 py-2 border border-gray-300 rounded-md"
                />
              </div>
              <div>
                <label htmlFor="description" className="block text-left text-sm font-medium text-gray-700">
                  Description
                </label>
                <textarea
                  id="description"
                  value={courseDetails.description}
                  onChange={(e) =>
                    setCourseDetails({ ...courseDetails, description: e.target.value })
                  }
                  className="w-full px-4 py-2 border border-gray-300 rounded-md"
                />
              </div>
              <div>
                <label htmlFor="image_path" className="block text-left text-sm font-medium text-gray-700">
                  Image Path
                </label>
                <input
                  type="text"
                  id="image_path"
                  value={courseDetails.image_path}
                  onChange={(e) =>
                    setCourseDetails({ ...courseDetails, image_path: e.target.value })
                  }
                  className="w-full px-4 py-2 border border-gray-300 rounded-md"
                />
              </div>
              <div>
                <label htmlFor="image" className="block text-left text-sm font-medium text-gray-700">
                  Module Image
                </label>
                <input
                  type="file"
                  id="image"
                  accept="image/*"
                  onChange={(e) => setCourseDetails({ ...courseDetails, image: e.target.files[0] })}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div className="flex justify-end space-x-4 mt-4">
              <button
                className="bg-red-400 px-4 py-2 rounded-md hover:bg-gray-300 text-white"
                onClick={closeModal}
              >
                Cancel
              </button>
              <button
                className="bg-[#EFD316] px-4 py-2 text-black rounded-md hover:bg-blue-600"
                onClick={handleSaveChanges}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      )}

      {isDeleteModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
            <h2 className="text-xl font-bold mb-4">Are you sure you want to delete this course?</h2>
            <div className="flex justify-between">
              <button
                className="bg-gray-200 px-4 py-2 rounded-md text-gray-700"
                onClick={() => setIsDeleteModalOpen(false)} // Close modal without action
              >
                No, Cancel
              </button>
              <button
                className="bg-red-500 text-white px-4 py-2 rounded-md"
                onClick={() => deleteCourse(courseToDelete)} // Proceed with deletion
              >
                Yes, Delete
              </button>
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

export default ManageCourse;

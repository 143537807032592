import React from 'react';
import Slider from 'react-slick';
import mainImage1 from '../images/Security.png'; // Your image
import mainImage2 from '../images/Cloud hosting-cuate.png'; // Your image
import mainImage3 from '../images/Secure.png';

// Carousel settings
const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
};

function SliderPage() {
    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-50">
            <div className="relative w-full max-w-5xl px-4 py-8">
                <Slider {...sliderSettings} className="w-full">
                    {/* Slide 1 */}
                    <div className="flex items-start justify-start px-4 py-8 w-full">
                        <div className="flex flex-col sm:flex-row items-start justify-start space-x-0 sm:space-x-4 w-full">
                            {/* Text Section */}
                            <div className="flex-1 max-w-md">
                                <h1 className="text-3xl sm:text-4xl font-bold text-black text-left">
                                    Discover Healing and Growth with{' '}
                                    <span className="text-[#FDD835]">Mercy Medical University.</span>
                                </h1>
                                <p className="mt-4 text-gray-700 text-base sm:text-lg text-left">
                                    Take charge of your mental well-being with our comprehensive CBT programs designed
                                    to empower individuals through self-awareness and practical coping strategies.
                                </p>
                                <div className="mt-4 flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
                                    <button className="px-6 py-2 flex items-center text-black border border-[#000000] rounded-lg hover:bg-[#FDD835] hover:border-[#FDD835] hover:text-[#FFFFFF] w-fit">
                                        Get Started
                                    </button>
                                    <button className="px-6 py-2 flex items-center bg-[#FDD835] text-black rounded-lg hover:bg-[#E6C300] w-fit">
                                        Explore Programs
                                    </button>
                                </div>
                            </div>

                            {/* Image Section */}
                            <div className="flex-none w-full sm:w-[300px] md:w-[350px] mt-4 sm:mt-0">
                                <img
                                    src={mainImage1}
                                    alt="Mindfulness Illustration"
                                    className="w-full h-auto object-cover rounded-[24px]"
                                />
                            </div>
                        </div>
                    </div>

                    {/* Slide 2 */}
                    <div className="flex items-start justify-start px-4 py-8 w-full">
                        <div className="flex flex-col sm:flex-row items-start justify-start space-x-0 sm:space-x-4 w-full">
                            {/* Text Section */}
                            <div className="flex-1 max-w-md">
                                <h1 className="text-3xl sm:text-4xl font-bold text-black text-left">
                                    Master Cognitive Tools for a Balanced Life
                                </h1>
                                <p className="mt-4 text-gray-700 text-base sm:text-lg text-left">
                                    Learn how cognitive restructuring, mindfulness, and therapy strategies can help you
                                    cope with challenges and build mental resilience.
                                </p>
                                <div className="mt-4 flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
                                    <button className="px-6 py-2 flex items-center text-black border border-[#000000] rounded-lg hover:bg-[#FDD835] hover:border-[#FDD835] hover:text-[#FFFFFF] w-fit">
                                        Get Started
                                    </button>
                                    <button className="px-6 py-2 flex items-center bg-[#FDD835] text-black rounded-lg hover:bg-[#E6C300] w-fit">
                                        Explore Programs
                                    </button>
                                </div>
                            </div>

                            {/* Image Section */}
                            <div className="flex-none w-full sm:w-[300px] md:w-[350px] mt-4 sm:mt-0">
                                <img
                                    src={mainImage2}
                                    alt="Cognitive Tools Illustration"
                                    className="w-full h-auto object-cover rounded-[24px]"
                                />
                            </div>
                        </div>
                    </div>

                    {/* Slide 3 */}
                    <div className="flex items-start justify-start px-4 py-8 w-full">
                        <div className="flex flex-col sm:flex-row items-start justify-start space-x-0 sm:space-x-4 w-full">
                            {/* Text Section */}
                            <div className="flex-1 max-w-md">
                                <h1 className="text-3xl sm:text-4xl font-bold text-black text-left">
                                    Join Our Therapy Sessions and Rebuild Your Path
                                </h1>
                                <p className="mt-4 text-gray-700 text-base sm:text-lg text-left">
                                    Participate in structured, evidence-based therapy sessions to overcome challenges
                                    and build lasting well-being.
                                </p>
                                <div className="mt-4 flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
                                    <button className="px-6 py-2 flex items-center text-black border border-[#000000] rounded-lg hover:bg-[#FDD835] hover:border-[#FDD835] hover:text-[#FFFFFF] w-fit">
                                        Get Started
                                    </button>
                                    <button className="px-6 py-2 flex items-center bg-[#FDD835] text-black rounded-lg hover:bg-[#E6C300] w-fit">
                                        Explore Programs
                                    </button>
                                </div>
                            </div>

                            {/* Image Section */}
                            <div className="flex-none w-full sm:w-[300px] md:w-[350px] mt-4 sm:mt-0">
                                <img
                                    src={mainImage3}
                                    alt="Therapy Session Illustration"
                                    className="w-full h-auto object-cover rounded-[24px]"
                                />
                            </div>
                        </div>
                    </div>
                </Slider>
            </div>
        </div>
    );
}

export default SliderPage;

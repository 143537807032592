import React, { useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CourseCreationModal = ({ onClose }) => {
    const [courseName, setCourseName] = useState('');
    const [description, setDescription] = useState('');
    const [image, setImage] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate form fields
        if (!courseName || !description || !image) {
            toast.error('All fields are required.');
            return;
        }

        const formData = new FormData();
        formData.append('course_name', courseName);
        formData.append('description', description);
        formData.append('image', image);

        try {
            const response = await axios.post('https://swiftsendify.com/learning_api/create_course.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            // Handle the response
            if (response.data.status === 'success') {
                toast.success('Course created successfully!');
                setCourseName('');
                setDescription('');
                setImage(null);
            } else {
                toast.error(`Error: ${response.data.message}`);
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            toast.error('An error occurred while creating the course.');
        }
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 animate-glitter">
            <ToastContainer position="top-right" autoClose={3000} style={{ top: '70px' }} />
            <div className="bg-white rounded-lg shadow-lg w-full max-w-lg p-6 relative">
                <button
                    className="absolute top-2 right-2 text-gray-500 hover:text-gray-800"
                    onClick={onClose}
                >
                    ✕
                </button>
                <h2 className="text-2xl font-bold mb-4 text-center text-black">Module Creation</h2>
                <form onSubmit={handleSubmit}>
                    {/* Course Name Field */}
                    <div className="mb-4">
                        <label className="block text-left text-gray-700 mb-2">Module Name</label>
                        <input
                            type="text"
                            className="w-full p-2 border border-gray-300 rounded text-black"
                            placeholder="Enter course name"
                            value={courseName}
                            onChange={(e) => setCourseName(e.target.value)}
                        />
                    </div>

                    {/* Description Field */}
                    <div className="mb-4">
                        <label className="block text-left text-gray-700 mb-2">Description</label>
                        <textarea
                            className="w-full p-2 border border-gray-300 rounded text-black"
                            placeholder="Enter course description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        ></textarea>
                    </div>

                    {/* Image Upload Field */}
                    <div className="mb-4">
                        <label className="block text-left text-gray-700 mb-2">Upload Image</label>
                        <input
                            type="file"
                            className="w-full p-2 border border-gray-300 rounded text-black"
                            onChange={(e) => setImage(e.target.files[0])}
                        />
                    </div>

                    {/* Submit Button */}
                    <div className="flex space-x-2 mt-4">
                        <button
                            type="submit"
                            className="bg-[#EFD316] text-[#0a2e3e] p-2 rounded w-full hover:bg-[#D4BC0D] animate-glitter"
                        >
                            Create Course
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CourseCreationModal;

import React from 'react';
import ReactDOM from 'react-dom/client'; // Import the createRoot API
import './index.css'; // Import Tailwind CSS
import App from './App';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Create a root and render the App
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

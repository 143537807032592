import React from 'react';
import Header from '../views/dashboard/header';
import Sidebar from './dashboard/sidebar';
import Overview from './dashboard/overview';

function Dashboard() {
    return (
        <div className="min-h-screen flex flex-col bg-gray-100">
            {/* Header */}
            <div className="fixed top-0 left-0 right-0 z-20">
                <Header />
            </div>

            {/* Layout */}
            <div className="flex flex-1 pt-16 md:pt-20">
                {/* Sidebar for Mobile and Desktop */}
                <div className="w-64 bg-white shadow-lg z-10">
                    <Sidebar />
                </div>

                {/* Main Content */}
                <div className="flex-1 p-4 md:p-6 lg:p-8 pt-20 md:pt-24">
                    <Overview />
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
